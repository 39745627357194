import { IResourceRequirement, IFund, ResourceSourceType, IProject, ResourceSource, ResourceCostCategory, ResourceUnit, TimeDimension, ICashFlowEntry, IProjectTask } from "@api/schema"



// @todo: rename to CURRENT_CASH_FLOW_ENTRY_TYPE
export const CURRENT_CASH_FLOW_TYPE = "CashFlowEntryDto_v1"
export const CURRENT_RESOURCE_REQUIREMENT_TYPE = "ResourceRequirementDto_v1"

/** special type for ResourceRequirements in the used form */
export type ResourceRequirementsFormValues = IResourceRequirement & { unitCost: number; quantity: number; costSubCategory: string /* CostSubCategory */ }

/**
 * a FreshCashFlowEntry delivers a pre-filled ICashFlowEntry
 */
export const FreshCashflowEntry: ICashFlowEntry = {
  "@type": CURRENT_CASH_FLOW_TYPE,
  timePoint: 0,
  unitCost: null,
  quantity: null,
  description: "",
}

/*
* a FreshResourceRequirement delivers a pre-filled IResourceRequirement
* must be instantiated with a task -> getFreshResourceRequirement
*/
export const FreshResourceRequirement: IResourceRequirement = {
  "@type": CURRENT_RESOURCE_REQUIREMENT_TYPE,
  cashFlow: [
    FreshCashflowEntry
  ],
  cashFlowTimeDimension: TimeDimension.UnSet,
  customUnit: "",
  unit: ResourceUnit.Flat,
  costCategory: ResourceCostCategory.UnSet,
  title: "",
  // NOTE: we must initialize workPackage with non-null value to be able to compare it
  // to "null" workPackages in PlanContainer.getResourceRequirementsByWorkPackageId
  // @todo check/verify if any other props must also be initialized (as soon as we use them
  // in calculations before sending it to the backend, which initializes every missing
  // value with empty strings).
  workPackage: ""
}


/**
 * Delivers a copy of a fresh IResourceRequirement, initiated with a given task (or null).
 *
 * @param task The initial task, the ResourceRequirement should be assigned to
 * @returns A fresh IResourceRequirement
 */
export const getFreshResourceRequirement = (task: IProjectTask): IResourceRequirement => {
  // prepare a fresh ResourceRequirement assigned to the current task
  const newResourceRequirement = Object.assign({}, FreshResourceRequirement)
  newResourceRequirement.task = task?.id
  return newResourceRequirement
}

/**
 * Filters for those ResourceRequirements by the given resourceType.
 *
 * @param resourceRequirements of the given fund or submission DTO.
 * @param resourceType which is used to filter the resourceRequirements.
 * @returns A filtered array of ResourceRequirements.
 */
export const getResourceRequirements = (resourceRequirements: IResourceRequirement[], resourceType: ResourceSourceType): IResourceRequirement[] => {
  return resourceRequirements?.filter(rr => rr.sourceType === resourceType) || []
}

/**
 * Filters for those ResourceRequirements, which are chosen to be funded by the given fund.
 *
 * @param resourceRequirements of the given fund or submission DTO.
 * @param fund the fund, which is chosen as source for funding
 * @returns A filtered array of ResourceRequirements.
 */
export const getResourceRequirementsFundedByFund = (resourceRequirements: IResourceRequirement[], fund: IFund): IResourceRequirement[] => {
  return resourceRequirements?.filter(rr => rr.sourceType === ResourceSourceType.Funding && rr.relatedSourceObject === fund?.id) || []
}



/**
 * Filters for those ResourceRequirements of the given project, which do not have a funding yet
 *
 * @param project the project, which ResourceRequirements should be checked
 * @returns An array of ResourceRequirements from the given project.
 */
export const getResourceRequirementsWithoutFunding = (project: IProject): IResourceRequirement[] => {
  return project?.resourceRequirements?.filter(rr => !Object.values(ResourceSourceType).includes(rr.sourceType)) || []
}

/**
 * Delivers an array of ResourceRequirements, that are funded by own contributions: own efforts and own materials
 *
 * @returns the list of contribution or an empty list, if no own contribution is set yet
 */
export const getResourceRequirementsSourcedByOwnContributions = (project: IProject): IResourceRequirement[] =>
  project?.resourceRequirements
    ? project.resourceRequirements.filter(res => res.sourceType === ResourceSourceType.OwnResources && (res.source === ResourceSource.OwnMaterialContribution || res.source === ResourceSource.OwnEffortContribution))
    : []

/**
 * Calculate the sum of all cashflow-entries in all given ResourceRequirements.
 */
export const sumRequirementsCosts = (requirements: IResourceRequirement[]): number => {
  let sum = 0
  requirements?.forEach(rr =>
    rr.cashFlow?.forEach(cf => sum = sum + cf.quantity * cf.unitCost)
  )
  // do a separate rounding, because when used 13 units * 87,7 unitcost in a CashFlow-Entry, the Javascript-Engine
  // adds unexpected additional 2.2737367544323206e-13 !!!
  return Math.round(sum * 100) / 100 // rounding to 2 digits after comma
}

/**
 * Calculate the sum of all costs in the given ResourceRequirements, that are assigned to the given CostCategory.
 */
export const sumCostsPerCostCategory = (requirements: IResourceRequirement[], costCategory: ResourceCostCategory): number => {
  return sumRequirementsCosts(requirements?.filter(rr => rr.costCategory === costCategory))
}


/**
 * Calculate the sum of all cashflow-entries of the project
 */
export const projectCosts = (project: IProject): number => {
  return sumRequirementsCosts(project?.resourceRequirements)
}


/**
 * Calculates the sum of a cashflow of a ResourceRequirement
 *
 * @param rr : the relevant ResourceRequirement
 * @returns the sum of the cashflow
 */
export const sumCashflowCost = (rr: IResourceRequirement): number => {
  let sum = 0
  // go thru all single cashflow-elements
  rr?.cashFlow?.forEach(cashflow => sum = sum + cashflow.quantity * cashflow.unitCost)
  return Math.round(sum * 100) / 100
}


/**
 * Calculate the sum of all cashflow-entries in the given ResourceRequirement.
 */
export const sumCostsOfResourceRequirement = (requirement: IResourceRequirement): number => {
  let sum = 0
  requirement?.cashFlow?.forEach(cf => sum = sum + cf.quantity * cf.unitCost)
  return Math.round(sum * 100) / 100
}

/**
 * Calculate the sum of quantity of all cashflow-entries in the given ResourceRequirement.
 */
export const sumQuantityOfResourceRequirement = (requirement: IResourceRequirement): number => {
  let sum = 0
  requirement?.cashFlow?.forEach(cf => sum = sum + cf.quantity)
  return Math.round(sum * 100) / 100
}

/**
 * Calculate the average of unitCost of all cashflow-entries in the given ResourceRequirement.
 */
export const averageUnitCostOfResourceRequirement = (requirement: IResourceRequirement): number => {
  let average = 0
  if (sumQuantityOfResourceRequirement(requirement) > 0) {
    average = sumCostsOfResourceRequirement(requirement) / sumQuantityOfResourceRequirement(requirement)
  }
  else {
    average = requirement?.cashFlow?.length > 0 ? requirement?.cashFlow[0].unitCost : 0
  }
  return Math.round(average * 100) / 100
}
