/**
 * Defines the type for an array of ContentItems
 */
export type ContentItem = {
  [key in AvailableContentItems]: {
    printable: boolean // is it printable to the PDF?
    printedByDefault?: boolean // is it printed by default?
    explanation?: string // option to explain, why the setting is how it is, e.g: project.impact is not printable, because it is printed via project.impactAnalysis
  }
}

/**
 * Available content items are a combination of content items for specific types, e.g. project- + applications-contents
 */
export type AvailableContentItems =
  (AvailableProjectContentItems | AvailableProposalContentItems)
  & (AvailableProjectContentItems | AvailableProposalContentItems | AvailableFundApplicationContentItems)

/**
 * available Content-Items for projects
 */
export enum AvailableProjectContentItems {
  // single elements for project-specifics
  ProjectName = "project.name",
  SelfAssessmentProfile = "project.selfassessment.profile",
  ShortDescription = "project.shortDescription",
  Description = "project.description",
  DescriptionExtension = "project.descriptionExtension",
  Challenges = "project.challenges",
  Goals = "project.goals",
  Vision = "project.vision",
  Categories = "project.categories",
  SDGs = "project.sdgs",
  Delimitation = "project.delimitation",
  ImplementationLocations = "project.implementationLocations",
  Map = "project.map",
  Picture = "project.picture",
  Visualization = "project.visualization",
  TargetGroups = "project.targetGroups",
  ExpectedInvolvedPersons = "project.expectedInvolvedPersons",
  Impact = "project.impact",
  Results = "project.results",
  Outcome = "project.outcome",
  Utilization = "project.utilization",
  Timetable = "project.timetable",
  OwnContributions = "project.ownContributions",
  Partners = "project.partners",
  // standing data
  BankDetails = "project.bankDetails",
  Holder = "project.holder",
  Contact = "project.contact",

  // explanations
  GoalExplanation = "project.goalExplanation",
  SDGExplanation = "project.sdgExplanation",

  // complex elements, @todo: should they be organised in another enum?
  CostFinancePlan = "project.costFinancePlan",
  ImpactAnalysis = "project.impactAnalysis",
  ResourcePlan = "project.resourcePlan",
  Workplan = "project.workPlan",
}

/**
 * available Content-Items for (challenge) proposals
 */
export enum AvailableProposalContentItems {
  // for Proposal-specifics
  ProposalAttachments = "proposal.attachments",
  ProposalConcretizations = "proposal.concretizations",
}

/**
 * available Content-Items for fund applications
 */
export enum AvailableFundApplicationContentItems {
  ProposalThirdPartyFundsExplanation = "proposal.thirdPartyFundsExplanation",
}