
import { FormikErrors } from "formik"

import {
  CostSubCategory,
  IChallenge,
  ICostSubCategory,
  IFund,
  IFundApplication,
  IGoal,
  IProject,
  IProjectMembership,
  IProjectTask,
  IProposal,
  IResourceRequirement,
  ImplementationState,
  MembershipRole,
  ProjectProgress,
  ProjectState,
  ProposalState,
  ResourceSource,
  ResourceSourceType,
  ResourceUnit,
  IWorkPackage,
  IUserProjectRole,
  IBankDetails,
  IUser,
  IProjectPartner,
  ICategory,
  IIdea,
  TimeDimension,
  ResourceCostCategory,
  PartnershipState,
  SDG,
  SelfAssessment,
} from "@api/schema"
import { BlockerContext, PermissionBlockers } from "@basics/blocker"
import { CURRENT_CASH_FLOW_TYPE, CURRENT_RESOURCE_REQUIREMENT_TYPE } from "@basics/resourceRequirements"
import { idFromIModelOrIRI, getIDs } from "@basics/util-importless"
import { IValidationErrors } from "@basics/validation"
import { IFilterCriteria } from "@redux/helper/actions"
import { OrderTypes } from "@redux/reduxTypes"
import { availableForSelection, challengeTypeAllowedForSelection } from "@services/challengeHelper"
import { prefixApiUrl } from "@services/util"
import { BASE_URL, SINN_PROTOTYPE_CLIENT_CONFIG_USED } from "config"

import { DynamicTranslate } from "./i18n"
import { countRoles, filterRoles } from "./userObjectRolesHelper"

/*
 * projectHelper: Collection of helper-functions for a IProject to be used in all components, dealing with calculation project-data.
 */

export interface IProjectFilterCriteria extends IFilterCriteria {
  /** one or more categories */
  categories?: number | number[] | string | string[]
  /** ID of one or more project(s) */
  id?: number | number[] | string | string[]
  /** implementationstate of one or more project(s) */
  implementationState?: ImplementationState | ImplementationState[]
  /** locked projects: only for processmanager */
  locked: boolean
  /** name of a project */
  name?: string
  /** search pattern */
  pattern?: string
  /** one or more SDGs */
  sdgs?: number | number[] | string | string[]
  /** Slug of one or more project(s) */
  slug?: string | string[]
  /** state of the project */
  state?: ProjectState

  "order[id]"?: OrderTypes
  "order[name]"?: OrderTypes
  "order[updatedAt]"?: OrderTypes
  "order[createdAt]"?: OrderTypes
  "order[memberCount]"?: OrderTypes
}

// @todo multi in enums umwandeln, z.bsp enum BankDetailsType { V1 = "BankDetailsDto_v1" }
// oder noch grundsätzlicher: @todo in number umwandeln: https://futureprojects.atlassian.net/browse/FCP-1645
export const CURRENT_ADDRESS_TYPE = "AddressDto_v1"
export const CURRENT_BANKDETAILS_TYPE = "BankDetailsDto_v1"
export const CURRENT_WORKPACKAGE_TYPE = "WorkPackageDto_v1"
export const CURRENT_COST_SUB_CATEGORY_TYPE = "CostCategoryDto_v1"
// @todo rename to CURRENT_PROJECT_GOAL_TYPE
export const CURRENT_GOAL_TYPE = "ProjectGoalDto_v1"
export const CURRENT_PROJECT_TASK_TYPE = "ProjectTaskDto_v1"
export const CURRENT_PROJECT_PARTNER_TYPE = "ProjectPartnerDto_v1"

/**
 * placeholder-image for projects: path, with and height
 */
export const projectIllustrationPlaceholder = {
  /** path in the public folder to be accessed via webserver */
  path: "/assets/img/placeholder/illustration-project-profile.svg",
  width: 439,
  height: 436
}

/**
 * Html anchor id for the map element in the project profile.
 * Used for direct browser navigation to the map.
 */
export const ANCHOR_FOR_PROJECT_POSITIONING = "positioning"

/**
 * shortDescription of a deleted project
 *
 * @todo currently for "normal users" this is the only option to recognize, if a project is deleted. deletedAt is not set! (Stand: 12.03.2023)
 * @deprecated @todo multi this was used in ProjectCard to calculate resulting project count from an idea without deleted projects,
 * but the idea has now a property called resultingProjectCount. Questions remains: Contains resultingProjectCount deleted projects?
 */
export const deletedProjectShortDescription = "deleted project"

/**
 * id of the own contributions view card
 */
export const idOwnContributionsView = "OwnContributionsView"

/**
 * ResourceWorkMode ist used as possible options for planning the ResourceRequirements,
 * to differenciate between complexe work-modes on ResourceRequirements
 */
export enum ResourceWorkMode {
  ResourcePlanning = "resources",
  FinancePlanning = "finance",
  CostCategoryPlanning = "costcategory",
}

export const currentCostSubCategoryVersion = 1

/* ************************************************************************** */
/* Pre-filled data for initial objects based on existing schmemas             */
/* using the prefix "Fresh" to have a standard in the code                    */
/* ************************************************************************** */

/**
 * FreshGoal delivers a pre-filled IGoal to be used as a standard fresh goal
 */
export const FreshBankDetails: IBankDetails = {
  "@†ype": CURRENT_BANKDETAILS_TYPE,
  accountOwner: null,
  bankName: null,
  bic: null,
  iban: null
}

/**
 * FreshGoal delivers a pre-filled IGoal to be used as a standard fresh goal
 */
export const FreshGoal: IGoal = {
  "@type": CURRENT_GOAL_TYPE,
  goal: "",
  measurability: "",
}

/**
 * FreshProjectMembership delivers a pre-filled IProjectMembership to be used as standard fresh ProjectMembership
 */
export const FreshProjectMembership: IProjectMembership = {
  motivation: "",
  skills: ""
}

/**
 * FreshProjectTask delivers a pre-filled IProjectTask to be used as a standard fresh project task
 */
export const FreshProjectTask: IProjectTask = {
  "@type": CURRENT_PROJECT_TASK_TYPE,
  description: ""
}

/**
 * a FreshCostSubCategory delivers a pre-filled ICostSubCategory
 * must be instantiated with a fund -> getFreshCostSubCategory
 */
const FreshCostSubCategory: ICostSubCategory = {
  "@type": CURRENT_COST_SUB_CATEGORY_TYPE,
  type: CostSubCategory.Other,
}

/**
 * a FreshWorkPackage delivers a pre-filled IWorkPackage
 */
export const FreshWorkPackage: IWorkPackage = {
  "@type": CURRENT_WORKPACKAGE_TYPE,
  description: null,
  mainResponsibility: null,
  name: "",
  order: null,
}

/**
 * all possible ProjectProgress-States, that stand for "planning" (and not for "idea")
 *
 * @todo multi refactor
 * => adapt to ImplementationState
 * => as a consequence marketFilterCriteriaPreparationForLoadCollection needs to be updated
 */
export const ProjectPlanStates = [
  ProjectProgress.CreatingProfile,
  ProjectProgress.CreatingPlan,
  ProjectProgress.CreatingProposal,
  ProjectProgress.SubmittingProposal,
  ProjectProgress.ProposalSubmitted,
  // excluding: ProjectProgress.Idea !
]



// #endregion team roles

/* ************************************************************************** */
/* helper-functions                                                           */
/* ************************************************************************** */

/**
 * Current locations, where the project picture will be displayed.
 */
export enum AreasToDisplayProjectImage {
  PublicProfile = "PublicProfile",
  PublicProfileShort = "PublicProfileShort",
  InternalProjectProfile = "InternalProjectProfile",
  ProjectCard = "ProjectCard"
}

/**
 * type of the function getProjectPictureProperties
 */
type ProjectPicturePropertiesReturnType<display extends AreasToDisplayProjectImage> =
  display extends AreasToDisplayProjectImage.InternalProjectProfile
  ? {
    imageUrl: string
    width: string
    height: string
  }
  : {
    imageUrl: string
    width: number
    height: number
  }

/**
 * A project has a picture or a pictureUrl. And this functions ensures
 * that the pictureUrl will be preferred before the picture.
 * The picture will be used, if no pictureUrl is set.
 * If none is set, the projectIllustrationPlaceholder will be used as a fallback.
 *
 * @return an object with following properties:
 * - imageUrl
 * - width
 * - height
 */
export const getProjectPictureOrPictureUrlProperties = <display extends AreasToDisplayProjectImage>(
  project: IProject,
  areaToDisplayProjectImage: display): ProjectPicturePropertiesReturnType<display> => {
  let height: string | number = null
  let width: string | number = null
  if (project.pictureUrl) {
    switch (areaToDisplayProjectImage) {
      // the picture should only take the available space
      case AreasToDisplayProjectImage.InternalProjectProfile:
        height = "100%"
        width = "100%"
        break
      // the picture should be displayed in his size
      case AreasToDisplayProjectImage.PublicProfileShort:
      case AreasToDisplayProjectImage.PublicProfile:
      case AreasToDisplayProjectImage.ProjectCard:
        height = undefined
        width = undefined
        break
    }
    return { imageUrl: project.pictureUrl, width, height } as ProjectPicturePropertiesReturnType<display>
  }
  if (project.picture) {
    switch (areaToDisplayProjectImage) {
      case AreasToDisplayProjectImage.InternalProjectProfile:
        height = project.picture.dimensions[0] < project.picture.dimensions[1]
          ? "100%"
          : (project.picture.dimensions[0] / project.picture.dimensions[1] * 100).toString() + "%"
        width = project.picture.dimensions[0] < project.picture.dimensions[1] ?
          (project.picture.dimensions[0] / project.picture.dimensions[1] * 100).toString() + "%"
          : "100%"
        break
      case AreasToDisplayProjectImage.PublicProfileShort:
      case AreasToDisplayProjectImage.PublicProfile:
      case AreasToDisplayProjectImage.ProjectCard:
        width = project.picture?.dimensions[0]
        height = project.picture?.dimensions[1]
        break
    }
    return { imageUrl: prefixApiUrl(project.picture.contentUrl), width, height } as ProjectPicturePropertiesReturnType<display>
  }

  return {
    imageUrl: BASE_URL + projectIllustrationPlaceholder.path,
    height: projectIllustrationPlaceholder.height,
    width: projectIllustrationPlaceholder.width
  } as ProjectPicturePropertiesReturnType<display>
}

// does the validation of resourceRequirements according to Backend > src/Dto/ResourceRequirement.php
// no restriction on relatedSourceObject
// NOTE: most of those validations may not be performed, because the browser performs similar checks earlier so that function is not called
export const validateResourceRequirement = (values: IResourceRequirement): IValidationErrors => {
  const errors: IValidationErrors = {}

  const title = values.title.trim()
  if (title.length === 0) {
    errors.title = "validate.general.notBlank"
  } else if (title.length < 3) {
    errors.title = "validate.general.tooShort"
  } else if (title.length > 100) {
    errors.title = "validate.general.tooLong"
  }

  const description = values.description.trim()
  if (description.length > 280) {
    errors.description = "validate.general.tooLong"
  }

  if (values.cashFlowTimeDimension === null || !Object.values(TimeDimension).includes(values.cashFlowTimeDimension)) {
    errors.cashFlowTimeDimension = "validate.general.invalidChoice"
  }

  if (!values.costCategory || !Object.values(ResourceCostCategory).includes(values.costCategory)) {
    errors.costCategory = "validate.general.invalidChoice"
  }

  if (values.costSubCategories?.length > 0) {
    const costSubCategoryErrorMessages: { [key: number]: { [key: string]: string } } = {}
    values.costSubCategories.forEach((valueCostSubCategory, index) => {
      costSubCategoryErrorMessages[index] = {}
      if (valueCostSubCategory.relatedFund !== undefined && (valueCostSubCategory.relatedFund < 1) ||
        (valueCostSubCategory.relatedFund > 99999999)) {
        costSubCategoryErrorMessages[index].relatedFund = "validate.relatedObject.invalid"
      }
      const type = valueCostSubCategory.type.trim()
      if (!type || type.length === 0) {
        costSubCategoryErrorMessages[index].type = "validate.general.notBlank"
      }
      if (type && type.length > 80) {
        costSubCategoryErrorMessages[index].type = "validate.general.tooLong"
      }
    })
    // only provide errors, if at least one error occured
    if (Object.keys(costSubCategoryErrorMessages).length > 0) errors.costSubCategory = costSubCategoryErrorMessages
  }

  if (values.sourceType && !Object.values(ResourceSourceType).includes(values.sourceType)) {
    errors.sourceType = "validate.general.invalidChoice"
  }

  if (values.source) {
    if (!Object.values(ResourceSource).find(value => values.source.match(value))) {
      errors.source = "validate.general.invalidChoice"
    }
  }

  const customSource = values.customSource?.trim()
  if (customSource?.length > 30) {
    errors.customSource = "validate.general.tooLong"
  }

  const customUnit = values.customUnit?.trim()
  if (customUnit?.length > 30) {
    errors.customUnit = "validate.general.tooLong"
  }

  if (!values.cashFlow || values.cashFlow?.length === 0) {
    errors.cashFlow = "validate.general.notBlank"
  } else {
    const cashFlow = values.cashFlow[0]
    const cashFlowErrorMessages: { [key: string]: string } = {}
    if (cashFlow) {
      const descriptionCashFlow = cashFlow.description?.trim()
      if (descriptionCashFlow?.length > 200) {
        cashFlowErrorMessages.description = "validate.general.tooLong"
      }
      if (cashFlow.quantity < 0 || cashFlow.quantity > 9999999.99) {
        cashFlowErrorMessages.quantity = "validate.general.outOfRange"
      }
      if (cashFlow.timePoint < 0 || cashFlow.timePoint > 99999999) {
        cashFlowErrorMessages.timePoint = "validate.general.outOfRange"
      }
      if (cashFlow.unitCost < 0 || cashFlow.unitCost > 9999999.99) {
        cashFlowErrorMessages.unitCost = "validate.general.outOfRange"
      }
      // only provide errors, if at least one error occured
      if (Object.values(cashFlowErrorMessages).length > 0) {
        errors.cashFlow = { 0: cashFlowErrorMessages }
      }
    }
  }

  const ownContributionsExplanation = values.ownContributionsExplanation?.trim()
  if (ownContributionsExplanation?.length > 200) {
    errors.ownContributionsExplanation = "validate.general.tooLong"
  }

  return errors
}

export const validateTask = (values: IProjectTask): FormikErrors<IProjectTask> => {
  const errors: IValidationErrors = {}

  const description = values.description.trim()
  if (description.length === 0) {
    errors.description = "validate.general.notBlank"
  } else if (description.length < 5) {
    errors.description = "validate.general.tooShort"
  } else if (description.length > 500) {
    errors.description = "validate.general.tooLong"
  }

  return errors
}

export const validateWorkPackage = (values: IWorkPackage): IValidationErrors => {
  const errors: IValidationErrors = {}

  const description = values.description ? values.description.trim() : ""
  if (description.length > 500) {
    errors.description = "validate.general.tooLong"
  }

  const name = values.name ? values.name.trim() : ""
  if (name.length === 0) {
    errors.name = "validate.general.notBlank"
  } else if (name.length < 2) {
    errors.name = "validate.general.tooShort"
  } else if (name.length > 100) {
    errors.name = "validate.general.tooLong"
  }

  const mainResponsibility = values.mainResponsibility ? values.mainResponsibility.trim() : ""
  if (mainResponsibility.length > 100) {
    errors.mainResponsibility = "validate.general.tooLong"
  }

  return errors
}

/**
 * @todo challenge: besserer Funktionsname? Zu Allgemein.
 * Checks if there are resourceRequirements linking to a fund, that is no longer available for proposals
 *
 * @todo multi maybe move to challengeHelper? Can't be move to resourceRequirementHelper,
 * b/c a circular dependency would be created between challengeHelper and resourceRequirementHelper.
 *
 * @param resourceRequirements A list of ResourceRequirements to check
 * @param funds to check for consistency
 * @returns true, if the given ResourceRequirements are not linked to an possible unavailable fund.
 */
export const checkRRRelatedSourceConsistency = (resourceRequirements: IResourceRequirement[], funds: IChallenge[]): boolean => {
  // precheck all given funds for its availability for proposals

  // prepare a list of all fund-IDs, that are available for proposals
  const validFunds = getIDs(funds?.filter(f => availableForSelection(f)))
  // search for at least one ResourceRequirement, that should be funded, has a relatedSourceObject and that
  // relatedSourceObject is not a valid challenge
  const foundRRWithDeprecatedFund = resourceRequirements?.find(rr => (rr.sourceType === ResourceSourceType.Funding) && rr.relatedSourceObject &&
    (!validFunds.includes(rr.relatedSourceObject)))
  return foundRRWithDeprecatedFund === undefined
}

/**
 * Delivers a copy of a fresh ICostSubCategory, initiated with a given fund (or null).
 *
 * @param fund The initial challenge, the SubCostCategory should be related to
 * @param category The initial SubCostCategory
 * @returns A fresh ICostSubCategory
 */
export const getFreshCostSubCategory = (fund: IFund, category?: CostSubCategory | string): ICostSubCategory => {
  // prepare a fresh ResourceRequirement assigned to the current task
  const newRRsub = Object.assign({}, FreshCostSubCategory)
  newRRsub.relatedFund = fund?.id
  if (category) newRRsub.type = category // overwrite the fresh type with the given one
  return newRRsub
}

/**
 * Returns all proposals of the project, that are not submitted yet
 *
 * @param project The project.
 * @returns all preparing (=not submitted) proposals
 */
export const getPreparingProposals = (project: IProject): (IProposal | IFundApplication)[] => {
  return project?.proposals?.filter((p) => p.state === ProposalState.Preparing)
}


/**
 * Returns helper to give goals the current type
 */
export const typeGoals = (goals: IGoal[]): IGoal[] => {
  return goals?.map((g) => {
    g["@type"] = CURRENT_GOAL_TYPE
    return g
  })
}

/**
 * Get the list of those projects, where the user is the only coordinator.
 *
 * @param membershipsOfProjects all memberships of all users of one or more projects
 * @param user the user to check, if the user is the only coordinator
 * @returns list of projects, where the user is the only coordinator.
 */
export const getProjectsWhereUserIsLastCoordinator = (membershipsOfProjects: IUserProjectRole[], user: IUser): IProject[] => {

  // list of those projects, where the user is the only coordinator
  const onlyCoordinatorProjects: IProject[] = []

  const rolesOfAUser: IUserProjectRole[] = membershipsOfProjects?.filter(role => role.user["@id"] === user?.["@id"])

  // list of the projects where the user is coordinator
  const coordinatedMemberships = filterRoles<IUserProjectRole>(rolesOfAUser, MembershipRole.Coordinator)

  // go through all its coordinator-memberships to watch for those projects, where he is the only coordinator within a team
  coordinatedMemberships?.forEach(role => {
    // count the coordinators within one project
    if (countRoles(membershipsOfProjects, MembershipRole.Coordinator, role.object) === 1) {
      onlyCoordinatorProjects.push(role.object)
    }
  })

  return onlyCoordinatorProjects
}

/**
 * This function filters the given list of ICategory for those that match the category-IRIs of the given IProject/IIdea.
 * @todo multi add Tests
 */
export const getICategoryListFromProjectOrIdea = (projectOrIdea: IProject | IIdea, categoryList: ICategory[]): ICategory[] =>
  projectOrIdea
    ? categoryList?.filter(cat => projectOrIdea.categories.includes(cat["@id"])) ?? []
    : []

/**
 * this function transforms an IProject (e.g. from a project-json load or when a project should be copied)
 * into an import-ready IProject by cleaning up import-relevant data
 *
 * @see /doc/migration-dev-to-multitenant.md
 *
 * @todo backend the backend should provide an import and export endpoint with version control.
 * Low priority => no customer use this function currently.
 *
 * @todo feedback to the user about importing limits: https://futureprojects.atlassian.net/browse/FCP-1608
 */
export const cleanupProjectToImport = (oldProject: IProject): IProject => {
  let newProject: IProject

  if (oldProject) {

    // to rework links on fund-relevant entities in ResourceRequirements
    const newResourceRequirements: IResourceRequirement[] = oldProject.resourceRequirements ?? []
    // to rework links to category objects
    // const newCategories: (ICategory | string)[] = []

    // assign a new ID to all ResourceRequirements and rework links to non existing objects
    newResourceRequirements.forEach((rr) => {
      // clear link to a possible relatedSourceObject
      if (rr.relatedSourceObject) {
        rr.relatedSourceObject = ""
        rr.source = ResourceSource.NoSource
      }
      // clear link to a possible relatedFund
      if (rr.costSubCategories && rr.costCategory.length > 0) {
        rr.costSubCategories = rr.costSubCategories.filter(csc =>
          csc.relatedFund === null
        )
      }
      // for older versions: rebuild cashflow, source, title, unit, version!
      switch (rr["@type"]) {
        case undefined:
        case null: {
          rr["@type"] = CURRENT_RESOURCE_REQUIREMENT_TYPE
          rr.title = rr.title ?? rr.description ?? "..."
          rr.description = ""
          rr.unit = ResourceUnit.Flat
          if (rr.sourceType === ResourceSourceType.Funding) {
            rr.source = ResourceSource.NoSource
          }
          // @todo: hier muss das ganze cashflow-array aufgearbeitet werden, nicht nur der erste Eintrag
          rr.cashFlow = [
            {
              "@type": CURRENT_CASH_FLOW_TYPE,
              quantity: rr.cashFlow[0].quantity ?? 1,
              unitCost: rr.cashFlow[0].unitCost ?? 0, // (oldProject.resourceRequirements as [])[index]?.cost ?? 0, // @todo: is there an easy way to access "cost"-attribute of old ResourceRequirements, when the given parameter is a "new" IProject?
              timePoint: rr.cashFlow[0].timePoint ?? 1,
            }
          ]
        }
      }
    })

    const newPartners: IProjectPartner[] = oldProject.partners ?? []
    newPartners.forEach((p) => {
      switch (p["@type"]) {
        case (null || undefined): {
          p["@type"] = CURRENT_PROJECT_PARTNER_TYPE
          p.address = {
            ...p.address,
            "@type": CURRENT_ADDRESS_TYPE,
          }
          if (!p.state) {
            p.state = PartnershipState.Open
          }
        }
      }
    })

    const newGoals: IGoal[] = oldProject.goals ?? []
    newGoals.forEach((g) => {
      switch (g["@type"]) {
        case (null || undefined): {
          g["@type"] = CURRENT_GOAL_TYPE
        }
      }
    })

    const newTasks: IProjectTask[] = oldProject.tasks ?? []
    newTasks.forEach((t) => {
      switch (t["@type"]) {
        case (null || undefined): {
          t["@type"] = CURRENT_PROJECT_TASK_TYPE
        }
      }

      if (!t.title) {
        t.title = ''
      }
      if (!t.result) {
        t.result = ''
      }
      // workpackage may be "null" when exported, but null is not accepted by the backend
      // so set it to undefined if it is no string
      if (typeof t.workPackage !== "string") {
        t.workPackage = undefined
      }
    })

    const newWorkpackages: IWorkPackage[] = oldProject.workPackages ?? []
    newWorkpackages.forEach((wp) => {
      switch (wp["@type"]) {
        case (null || undefined): {
          wp["@type"] = CURRENT_WORKPACKAGE_TYPE
        }
      }
    })


    /** pre 2024 ISDG structure */
    interface IOldSdg {
      id: SDG
    }

    let newSDGs: SDG[] = []

    // importing old (pre 2024) SDGs
    if (oldProject.sdgs.length > 0 && typeof oldProject.sdgs[0] !== "number") {
      const oldSdgs: IOldSdg[] = oldProject.sdgs as unknown as IOldSdg[]
      oldSdgs.forEach(sdg => newSDGs.push(sdg.id))
    } else {
      // importing new (post 2024) SDGs
      newSDGs = oldProject.sdgs
    }


    newProject = {
      bankDetails: oldProject.bankDetails,
      // progress: /* oldProject.progress ?? */ ProjectProgress.CreatingProfile, // currently, the backend needs a fresh start of the ProjectProgress
      shortDescription: oldProject.shortDescription,
      description: oldProject.description,
      challenges: oldProject.challenges,
      holder: oldProject.holder,
      // categories: newCategories as string[], // @todo multi update import => will be a list IRIs
      contact: oldProject.contact,
      delimitation: oldProject.delimitation,
      descriptionExtension: oldProject.descriptionExtension,
      expectedInvolvedPersons: oldProject.expectedInvolvedPersons,
      geoJson: oldProject.geoJson,
      goalExplanation: oldProject.goalExplanation,
      goals: newGoals,
      // not: implementationstate
      // not: inspiration -> must not be reused by imported projects!
      impact: oldProject.impact,
      implementationBegin: oldProject.implementationBegin,
      implementationTime: oldProject.implementationTime,
      implementationLocations: oldProject.implementationLocations,
      // not: membercount
      // not: memberships
      outcome: oldProject.outcome,
      partners: newPartners,
      // not: planSelfAssessment
      // not: pdfFile
      // not: picture
      // not: process -> // process-ID will be injected by saga before sending the new project-data to the backend-api
      // not: profileSelfAssessment
      results: oldProject.results,
      // not: resultingProjects
      resourceRequirements: newResourceRequirements,
      sdgs: newSDGs,
      sdgExplanation: oldProject.sdgExplanation,
      // not: slug
      // not: state
      targetGroups: oldProject.targetGroups,
      tasks: newTasks,
      // not: teamContact
      // not: teamUploads
      // not: updatedAt
      // not: usedMemberRole
      // not: usedRoles
      utilization: oldProject.utilization,
      vision: oldProject.vision,
      // not: visualization
      workPackages: newWorkpackages,

    }
  }

  return newProject
}

/** ****************************************************************************************
 * The following section is about helper methods for handling the project workflow
 *
 * Follow links should be provided on certain pages, if the user has the rights and the conditions for the links are fullfilled:
 * 'which page' => left link, center link, right link:
 *
 * Projektplan => projekt-profil, project-dashboard, challenge-selection|proposal-dashboard
 * project-profile => project-dashboard, project-plan
 *******************************************************************************************/

/**
 * Checks, if the profile self assessment is ready for the next step.
 * E.g. used to determine, which ui should be visible and/or accessible.
 *
 * @param project
 * @returns true, if the profile self assessment is ready for the next step.
 */
export const profilesSelfAssessmentIsReadyForNextStep = (project: IProject): boolean =>
  !SINN_PROTOTYPE_CLIENT_CONFIG_USED ? project.profileSelfAssessment === SelfAssessment.Complete : true

/**
 * Checks, if the plan self assessment is ready for the next step.
 * E.g. used to determine, which ui should be visible and/or accessible.
 *
 * @param project
 * @returns true, if the plan self assessment is ready for the next step.
 */
export const planSelfAssemementIsReadyForNextStep = (project: IProject): boolean =>
  !SINN_PROTOTYPE_CLIENT_CONFIG_USED ? project.planSelfAssessment === SelfAssessment.Complete : true

/**
 * The project plan page should be accessible,
 * if the profile self assessment is ready for the next step.
 *
 * @see profilesSelfAssessmentIsReadyForNextStep
 *
 * @param project
 * @returns true,if a proposal overview should be available
 */
export const pageProjectPlanIsAccessible = (project: IProject): boolean =>
  profilesSelfAssessmentIsReadyForNextStep(project)

/**
 * A proposal overview should be available,
 * if a project has at least one proposal and one proposal is active
 * and if the profile self assessment as well as the plan self assessment is ready for the next step.
 *
 * @param project
 * @returns true,if a proposal overview should be available
 * @todo multi refactor => proposals are fetched via SubRessource endpoint
 * proposalOverview has to be visible and accessible,
 * if planSelfAssessment and profileAssessment is complete or one proposal exists
 */
export const pageProposalOverviewIsVisibleAndAccessible = (project: IProject): boolean =>
  project && project.proposals?.length >= 1
  && project.proposals?.filter(p => p.active).length === 1
  && pageChallengeSelectionIsVisibleAndAccessible(project)

/**
 * A challenge selection page should be accessible,
 * if the profile self assessment as well as the plan self assessment is ready for the next step.
 *
 * @param project
 * @returns true, if a challenge selection should be available
 */
export const pageChallengeSelectionIsVisibleAndAccessible = (project: IProject): boolean => {
  return profilesSelfAssessmentIsReadyForNextStep(project)
    && planSelfAssemementIsReadyForNextStep(project)
}

/**
 * A challenge is selectable,
 * if the proposal is not yet submitted or finished
 * and the challenge is still available for selection (published, open und deadline not reched yet)
 * and if the profile self assessment as well as the plan self assessment is ready for the next step.
 *
 * @param proposal
 * @param activeChallenge
 * @returns true, if a challenge selection should be available
 */
export const shouldChallengeBeSelectable = (proposal: IProposal | IFundApplication, activeChallenge: IChallenge, project: IProject): boolean => {
  return proposal && proposal.state === ProposalState.Preparing
    && idFromIModelOrIRI(proposal.challenge) === activeChallenge?.id
    && availableForSelection(activeChallenge)
    && challengeTypeAllowedForSelection(activeChallenge["@type"])
    && pageChallengeSelectionIsVisibleAndAccessible(project)
}

/**
 * Generate the project name text from a given project entity, using the new i18n model.
 *
 * NOTE (@todo remove this note): there is another `projectNameText` in /src/components/project/ProjectNameText.tsx
 * that has basically the same logic, but works with the old/depracated i18n.Translate function instead
 * of our new (namespace-aware) DynamicTranslate function.
 * The other/old method will be removed once the transition to the new (dynamic) i18n model is completed.
 *
 * @todo rename this method to `projectNameText`, and fix method description in JSDoc above
 *
 * @param project
 * @param t
 * @returns
 */
export const projectNameTextWithDynamicNamespaces = (project: IProject, t: DynamicTranslate): string =>
  project && (project?.name
    ? project.name
    : t("project", "unnamedProject", { id: project?.id })
  )
// #region blockers

/**
 * @param isCoordinator is a user coordinator?
 * @returns blockers that block acting as coordinator
 */
export const blockerToPerformCoordinatorAction = (isCoordinator: boolean): BlockerContext => {
  const blockers: BlockerContext = {
    blocker: []
  }

  if (!isCoordinator) {
    blockers.blocker.push(PermissionBlockers.CoordinatorRequired)
  }

  return blockers
}
// #endregion
