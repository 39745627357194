import { EntityType, StatisticsType, UploadType } from "@redux/reduxTypes"

import { SubResourceUrlPattern, IRIstub, IModel, IRI } from "./schema"

/**
 * type of an endpoint that is implemented as sub resource of a entity endpoint by the backend API
 */
interface SubResourceEndpoint {
  /**
   * URL of the sub endpoint, e.g. "/projects/[id]/memberships".
   * This allows also completely different endpoint targets, different from the url of the base entity.
   */
  url: SubResourceUrlPattern
  /**
   * The API omits (mostly/always?) the property that contains the parent entity IRI.
   * To have full information this property name is deposited here.
   *
   * Example: UserObjectRoles fetched from the users sub resource endpoint are missing the "user" prop.
   * So "user" should be put here.
   *
   * NOTE: this is for information purposes only (24.04.2024)
   */
  nameOfOmittedParentProperty?: string
}


/**
 * type of an array of sub resources under a parent entity point
 */
type SubResourceList = {
  [key in EntityType]?: SubResourceEndpoint
}

/**
 * this data defines an endpoint
 */
interface Endpoint {
  /**
   * Url of an endpoint also matches the IRI stub of an entity.
   * Url must not end with a slash.
   */
  url: IRIstub
  /**
   * context string: what the API delivers as HydraCollection "@context"
   */
  "@context"?: string
  /**
   * optional sub resources that may be fetched from sub-urls of this endpoint
   * or used to trigger actions
   */
  subResource?: SubResourceList
}

/**
 * an endpoint for a specific entity is defined as
 * EntityType => Endpoint
 */
type EntityEndpointList = {
  [key in EntityType]: Endpoint
}

/**
 * an endpoint for a specific statistic is defined as
 * StatisticsType => Endpoint
 */
type StatisticsEndpointList = {
  [key in StatisticsType]: Endpoint
}

/**
 * an endpoint for a upload is defined as
 * UploadType => Endpoint
 */
type UploadEndpointList = {
  [key in UploadType]: Endpoint
}

// #endregion

// #region endpoint definitions

/**
 * This list contains all entity-specific endpoints
 * to be used in the generic functions to create, update, delete, load single elementes and collections
 * of an entity.
 *
 * Every url is identical with the central part of an "@id" of an IModel, called IRI.
 * So with this list it is also possible to calculate the EntityType from an IRI, done in
 * entitytypeFromIri()
 */
export const entityEndpointList: EntityEndpointList = {
  [EntityType.AttachmentDefinition]: {
    url: "/attachment_definitions",
    "@context": "/contexts/AttachmentDefinition",
  },
  [EntityType.Category]: {
    // this url is only used, when a category needs to be updated or deleted
    url: "/categories",
    "@context": "/contexts/Category",
  },
  [EntityType.Challenge]: {
    url: "/challenges",
    "@context": "/contexts/Challenge",
    subResource: {
      [EntityType.Program]: {
        url: "/challenges/[id]/related_programs"
      },
      [EntityType.UserObjectRole]: {
        url: "/challenges/[id]/managers"
      }
    }
  },
  [EntityType.ChallengeConcretization]: {
    url: "/challenge_concretizations",
    "@context": "/contexts/ChallengeConcretization",
  },
  [EntityType.Discussion]: {
    url: "/discussions",
    "@context": "/contexts/Discussion",
  },
  [EntityType.FeedbackInvitation]: {
    url: "/feedback_invitations",
    "@context": "/contexts/FeedbackInvitation",
  },
  [EntityType.FeedbackPost]: {
    url: "/feedback_posts",
    "@context": "/contexts/FeedbackPost",
  },
  [EntityType.Idea]: {
    url: "/ideas",
    "@context": "/contexts/Idea",
  },
  [EntityType.Program]: {
    url: "/programs",
    "@context": "/contexts/Program",
    subResource: {
      [EntityType.Category]: {
        // used to create a category or get all categories of a program
        url: "/programs/[id]/categories"
      },
      [EntityType.Challenge]: {
        url: "/programs/[id]/related_challenges"
      },
      [EntityType.Idea]: {
        url: "/programs/[id]/ideas",
      },
      [EntityType.Project]: {
        url: "/programs/[id]/projects"
      },
      [EntityType.UserObjectRole]: {
        url: "/programs/[id]/managers",
        nameOfOmittedParentProperty: "program"
      },
    }
  },
  [EntityType.Project]: {
    url: "/projects",
    "@context": "/contexts/Project",
    subResource: {
      [EntityType.Proposal]: {
        url: "/projects/[id]/proposals"
      },
      // create a team upload and get collection of team uploads
      [EntityType.TeamUpload]: {
        url: "/projects/[id]/team_uploads",
      },
      [EntityType.UserObjectRole]: {
        url: "/projects/[id]/memberships"
      }
    }
  },
  [EntityType.ProjectFollowership]: {
    url: "/project_followerships",
    "@context": "/contexts/ProjectFollowership",
  },
  [EntityType.ProjectMembership]: {
    url: "/project_memberships",
    "@context": "/contexts/ProjectMembership",
  },
  [EntityType.Proposal]: {
    url: "/proposals",
    "@context": "/contexts/Proposal",
  },
  [EntityType.ProposalAttachment]: {
    url: "/proposal_attachments",
    "@context": "/contexts/ProposalAttachment",
  },
  [EntityType.Provider]: {
    url: "/providers",
    subResource: {
      [EntityType.Program]: {
        url: "/providers/[id]/programs",
      },
      [EntityType.Challenge]: {
        url: "/providers/[id]/challenges"
      },
      [EntityType.UserObjectRole]: {
        url: "/providers/[id]/managers",
      },
    }
  },
  [EntityType.SupportRequest]: {
    url: "/support_requests",
    "@context": "/contexts/SupportRequest",
  },
  // only for edit and delete a team upload
  [EntityType.TeamUpload]: {
    url: "/team_uploads",
    "@context": "/contexts/TeamUpload",
  },
  [EntityType.User]: {
    url: "/users",
    "@context": "/contexts/User",
    subResource: {
      [EntityType.UserObjectRole]: {
        url: "/users/[id]/object_roles",
        nameOfOmittedParentProperty: "user"
      },
      [EntityType.Idea]: {
        url: "/users/[id]/ideas",
      },
      [EntityType.Project]: {
        url: "/users/[id]/projects",
      },
    }
  },
  [EntityType.UserObjectRole]: {
    // @todo multi: or set this to NULL, since it is neither a true endpoint nor an entity IRI prefix? (same for Category)
    // This should be probably completely deleted, because it is a subressource. Therefor the EntityEndpointList has to be redefined:
    // export type EntityEndpointList = {
    //  [key in Exclude<EntityType, EntityType.UserObjectRole>]: Endpoint
    // }
    url: "/object_roles"
  }
}


/**
 * This list contains all statistic-specific endpoints
 * to be used in the generic functions to load them.
 */
export const statisticsEndpointList: StatisticsEndpointList = {
  [StatisticsType.Challenges]: {
    url: "/challenges/statistics",
  },
  [StatisticsType.Platform]: {
    url: "/statistics",
  },
  [StatisticsType.Projects]: {
    url: "/projects/statistics",
  },
  [StatisticsType.User]: {
    url: "/users/statistics",
  },
}


/**
 * This list contains most upload-specific endpoints
 * to be used in the generic functions to upload (create), update or delete a file.
 *
 * Because a file is usually bound to a specific entity that is identified by its id
 * the URL usually uses [id] as placeholder to be replaced by the real id by the upload function.
 */
export const uploadEndpointList: UploadEndpointList = {
  [UploadType.ConcretizationImage]: {
    url: "/challenge_concretizations/[id]/image",
  },
  [UploadType.ChallengeLogo]: {
    url: "/challenges/[id]/logo",
  },
  [UploadType.ProcessLogo]: {
    url: "/programs/[id]/logo",
  },
  [UploadType.ProjectPicture]: {
    url: "/projects/[id]/picture",
  },
  [UploadType.ProjectVisualization]: {
    url: "/projects/[id]/visualization",
  },
  [UploadType.UserPicture]: {
    url: "/users/[id]/picture",
  }
}

// #region endpointList helper


/**
 * Returns the corresponding EntityType on a given IRI/"@id".
 *
 * NOTE that E is optional. Coders may provide a restricted / sub type of EntityType as defined in /src/redux/reduxTypes.ts.
 * Providing E has no effect on the internals of this method, since generic type parameters are not available at runtime.
 * Hence it's not possible to ensure that the given IRI is in the domain of E.
 * However, providing E allows coders to statically type the return type of this method, allowing them to call
 * further methods that only work on a restricted / sub type of EntityType.
 *
 * @todo find a way to ensure IRIs match the EntityType (FCP-1260)
 *
 * @param iri a valid IRI (pattern: /entity_endpoint_url/8) from an IModel[@id] to identify a single entity
 * @returns the corresponding EntityType
 */
const entityTypeFromIRI = <E extends EntityType>(iri: IRI): E => {
  if (!iri) {
    return null
  }

  // first character must be a /
  if (!iri.startsWith("/")) {
    return null
  }

  const indexOfSecondSlash = iri.indexOf("/", 2) // start searching on position after first character
  // no second slash found? -> no IRI!
  if (indexOfSecondSlash === -1) {
    return null
  }

  // calculate the isolated URL
  const urlFromIri = iri.substring(0, indexOfSecondSlash)

  // find the key of the corresponding entry in the entityEndpointList -> this is the searched EntityType-value!
  let entityType: string
  Object.entries(entityEndpointList).forEach(endpoint => {
    // @todo multi: es gibt entity typen, deren IRI sich von der URL in der entityEndpointList unterscheidet, e.g. UserObjectRoles
    // vgl https://futureprojects.atlassian.net/browse/FCP-1538
    if (endpoint[1].url === urlFromIri) {
      entityType = endpoint[0]
      return
    }
  })
  return entityType as E
}

/**
 * Returns the EntityType of a given IModel
 *
 * {@link entityTypeFromIRI} for notes on type safety.
 *
 * @param entity an IModel with valid "@id" or a plain "@id"/IRI
 * @returns the EntityType of the given entity
 */
export const entityTypeFromIModelOrIRI = <E extends EntityType>(entity: IModel | IRI): E => {
  // if no entity is given
  if (!entity) {
    return null
  }

  if (typeof entity === "string") {
    return entityTypeFromIRI<E>(entity)
  } else {
    if (entity["@id"]) {
      return entityTypeFromIRI<E>(entity["@id"])
    }
    else {
      // if there is no "@id"
      return undefined
    }
  }
}

/**
 * Returns the IRI ("@id") for an IModel of given EntityType with the given id.
 *
 * @param entityType The type of the IModel
 * @param id The id of the IModel
 * @returns the iri/"@id" as string
 *
 * @deprecated EntityTypes may have multiple IRI prefixes - find a more specific solution (it's only used with currentUser, anyhow)
 */
export const iriFromEntityTypeAndId = (entityType: EntityType, id: number): IRI =>
  // @todo multi: es gibt entity typen, deren IRI sich von der URL in der entityEndpointList unterscheidet, e.g. UserObjectRoles
  `${entityEndpointList[entityType].url}/${id}`

// #endregion

// #region helper on IDs or IRIs calculation/extraction of IModel types

/**
 * Returns the IRI ("@id") of an IModel or a string depending on the type of the given parameter.
 * Should be used when an entity property is of type "string | IModel" to have a shortcut to get the
 * IRI. If the type is string it is expected that it contains the IRI ('@id').
 *
 * @param iriElement represents an IRI or an IModel
 * @returns the iriElement as string if it is a string (which should be also an @id) or the "@id" of the IModel
 */
export const iriFromIModelOrIRI = (iriElement: IModel | IRI): IRI => {
  if (typeof iriElement === "string") {
    // calculate iristub by searching for slash after position 1, assuming slash at position 0
    const iristub = iriElement.substring(0, iriElement.indexOf("/", 1))
    // check, if the IRIsub is a known endpoint
    // @todo multi: es gibt entity typen, deren IRI sich von der URL in der entityEndpointList unterscheidet, e.g. UserObjectRoles
    if (Object.values(entityEndpointList).find(iri => iri.url === iristub)) {
      return iriElement
    }
    else {
      // if the IRIstub is not part of known endpoints
      return undefined
    }
  } else {
    return iriElement?.["@id"]
  }
}

/**
 * Returns if a given IModel or IRI correlates to a given EntityType
 *
 * @param entity an IModel with valid "@id" or a "@id"/IRI
 * @param entityType the EntityType the entity should match
 * @returns true, if the given entity has the given EntityType
 */
export const isOfEntityType = (entity: IModel | IRI, entityType: EntityType): boolean => {
  return entityTypeFromIModelOrIRI(entity) === entityType
}
// #endregion