import { IProposalContent, ObligationState } from "@api/schema"
import { AvailableContentItems, AvailableProjectContentItems, AvailableProposalContentItems, ContentItem } from "@api/schema/content-items"

/**
 * This ContentManagementHelper-component provides functions to handle ContentItems.
 *
 * These functions are provided to let the ChallengeManager (or other) chose from a given list of content-fields, that belong
 * to a project: which of those content-fields should be printed in the PDF of the proposal?
 *
 * There are three possible options, defined in schema:ObligationState:
 * Required: The content must be provided by the project team to have a chance of support
 * Optional: The team is free in providing this content, but it will not effect the proposal in relevant form
 * Notwanted: The content will not be put into the proposal-PDF.
 *
 * ContentItems are also used in the Feedback-System to specify, what project-content-element a user is commenting on.
 * They also may be used to lock form-elements in collaborating-situation (via the RTC-system), if one user wants to edit
 * the same project or the same content in a project as another user is doing at the same time.
 */

/**
 * default settings for available ContentItems (proposal for a challenge)
 */
export const DefaultProposalPDFContentSettings: ContentItem = {
  // project-specific:
  "project.name": {
    printable: true,
    printedByDefault: true
  },
  "project.selfassessment.profile": {
    printable: false
  },
  "project.shortDescription": {
    printable: true,
    printedByDefault: true
  },
  "project.description": {
    printable: true,
    printedByDefault: true
  },
  "project.descriptionExtension": {
    printable: true,
    printedByDefault: false
  },
  "project.challenges": {
    printable: true,
    printedByDefault: true
  },
  "project.goals": {
    printable: true,
    printedByDefault: true
  },
  "project.vision": {
    printable: true,
    printedByDefault: true
  },
  "project.categories": {
    printable: true
  },
  "project.sdgs": {
    printable: true,
    printedByDefault: true
  },
  "project.delimitation": {
    printable: true,
    printedByDefault: true
  },
  "project.implementationLocations": {
    printable: true,
    printedByDefault: true
  },
  "project.map": {
    printable: false
  },
  "project.picture": {
    printable: true,
    printedByDefault: true
  },
  "project.visualization": {
    printable: true,
    printedByDefault: true
  },
  "project.targetGroups": {
    printable: true,
    printedByDefault: true
  },
  "project.expectedInvolvedPersons": {
    printable: true,
    printedByDefault: false
  },
  "project.impact": {
    printable: false,
    printedByDefault: false,
    explanation: "is printed within complex ImpactAnalysis"
  },
  "project.results": {
    printable: false,
    printedByDefault: false,
    explanation: "is printed within complex ImpactAnalysis"
  },
  "project.outcome": {
    printable: false,
    printedByDefault: false,
    explanation: "is printed within complex ImpactAnalysis"
  },
  "project.utilization": {
    printable: true,
    printedByDefault: false
  },
  "project.timetable": {
    printable: true,
    printedByDefault: true
  },
  "project.ownContributions": {
    printable: true,
    printedByDefault: true
  },
  "project.partners": {
    printable: true,
    printedByDefault: false
  },
  "project.bankDetails": {
    printable: true,
    printedByDefault: true
  },
  "project.holder": {
    printable: true,
    printedByDefault: true
  },
  "project.contact": {
    printable: true,
    printedByDefault: true
  },

  // explanations
  "project.goalExplanation": {
    printable: true,
    printedByDefault: false
  },
  "project.sdgExplanation": {
    printable: true,
    printedByDefault: false
  },

  // complex elements
  "project.costFinancePlan": {
    printable: true,
    printedByDefault: true
  },
  "project.impactAnalysis": {
    printable: true,
    printedByDefault: false
  },
  "project.resourcePlan": {
    printable: true,
    printedByDefault: true
  },
  "project.workPlan": {
    printable: true,
    printedByDefault: true
  },


  // proposal-specific:
  "proposal.attachments": {
    printable: true,
    printedByDefault: true
  },
  "proposal.concretizations": {
    printable: true,
    printedByDefault: true
  },
  /* "proposal.thirdPartyFundsExplanation": {
      printable: true,
      printedByDefault: false
  },*/
}

/** current used version for IProposalContent */
export const iProposalContentVersion = 2

/**
 * returns if a given key in an array of given IProposalContent is required
 */
export const contentForProposalRequiredByChallenge = (contentKey: AvailableProjectContentItems | AvailableProposalContentItems, proposalContents: IProposalContent[]): boolean => {
  return proposalContents?.find(k => k.contentId === contentKey && k.requirement === ObligationState.Required) !== undefined
}

/**
 * Creates an array of IProposalContent from given contentItems
 */
export const createIProposalContentFromContentItem = (contentItems?: ContentItem): IProposalContent[] => {
  // use given list of ContentItem or the default
  const usedContent = contentItems || DefaultProposalPDFContentSettings
  const resultingProposalContent: IProposalContent[] = []
  Object.keys(usedContent).map((key: AvailableContentItems) => {
    if (usedContent[key].printable === true && usedContent[key].printedByDefault === true) {
      resultingProposalContent.push(createIProposalContent(key, ObligationState.Required))
    }
  })
  return resultingProposalContent
}

/**
 * creates a fresh IProposalContent with current version
 *
 * @param contentId a valid content id
 * @param requirement a valid ObligationState
 * @returns a valid IProposalContent
 */
export const createIProposalContent = (contentId: AvailableContentItems, requirement: ObligationState): IProposalContent => {
  return {
    contentId,
    requirement,
    version: iProposalContentVersion
  }
}