// #region Timeline handling

import { IRI, INumericIdentifierModel, ISlugAndNumericIdentifierModel } from "@api/schema"

/**
 * Interface to define steps/phases on a timeline
 */
export interface TimelinePhase {
  /** day when a phase starts */
  startDate: string
  /** number of the phase, to be able to compare (e.g. if (phase >= 2)... */
  phase: number
}


/**
 * calculates from a given Timeline the latest phase in which todays date is lying in
 *
 * @param timeline an array of timeline
 * @returns
 */
export const getLatestTimelinePhase = (timeline: TimelinePhase[]): TimelinePhase => {
  let latestDate: TimelinePhase = { startDate: "0", phase: null }

  // find out this date in the timeline, that is the newest/latest, but is in the past compared to today
  timeline.forEach(step => {
    const today = new Date()
    const dateToCheck = new Date(step.startDate)
    if (dateToCheck > new Date(latestDate.startDate) && today >= dateToCheck) {
      latestDate = step
    }
  })

  return latestDate
}
// #endregion

// #region html id
/**
 * converts an string with potentially special characters to a string useable as id of an HTML element
 *
 * the HTML element id attribute should start with a character to be acceptable e.g. for tooltip targets
 * and must not have whitespaces
 * NOTE: function removes all characters until a leading letter emerges to match the convention:
 * that may produce problems for strings containing just of special characters or numbers
 *
 * @see https://www.w3schools.com/html/html_id.asp
 *
 * NOTE that HTML element ids MUST be unique per HTML page. Users of this method must ensure that no two components
 * use this method on the same page; or that there is one main component that uses the generated id as-is and other
 * components add component-specific sub-strings to the generated id.
 *
 * @param s a string
 * @returns a converted and useable html id based on the given string
 *
 * @todo make sure, html id is always a (unique) string: FCP-1656
 */
export const stringAsHtmlId = (s: string): string => {
  if (!s) {
    return undefined
  }

  // replace all special characters by _ and remove all leading _ and other special characters as well
  // as numbers afterwards to let the string start with a letter
  // (that may lead to "empty" strings if it contains no letters)
  return s?.replaceAll(/\W/g, "_").replace(/^[^a-zA-Z]*/, '')
}

// #endregion

// #region object functions
/**
 * Test, if the object is empty, null or undefined
 *
 * @param inputObject
 * @returns true, if the object is empty, null or undefined
 */
export const isEmptyNullOrUndefinedObject = (inputObject: object): boolean => {
  return inputObject === undefined
    || inputObject === null
    || (
      inputObject &&
      Object.keys(inputObject).length === 0 &&
      inputObject.constructor === Object
    )
}

type Entry<T> = {
  [K in keyof T]: [K, T[K]]
}[keyof T]

/**
 * Allows to filter objects, e.g.
 *
 * IBus { name: string, speed: number}
 * const busListe = [ {E-Bus, 22}, {Diesl-Bus, 12}, {Wasserstoff-Bus,32}]
 * filterObject<IBus>(busListe, ([property,value]) => property ==="E-Bus" || (property === "speed" && value > 20))
 * Result: [ {E-Bus, 22}, {Wasserstoff-Bus,32}]
 *
 * Adapted from: https://www.steveruiz.me/posts/how-to-filter-an-object
 */
export const filterObject = <T extends object>(
  obj: T,
  fn: (entry: Entry<T>, index: number, array: Entry<T>[]) => boolean
): {
  [k: string]: T[keyof T]
} => {
  return Object.fromEntries(
    (Object.entries(obj) as Entry<T>[]).filter(fn)
  )
}

// #endregion

// #region array function

/**
 * This method is only useful as parameter for array.filter.
 * The method effectively checks whether the given value does not exist again before the given index.
 * I.e. it returns true if the given value-index-pair is the value's first occurrence in the array.
 *
 * May be used to keep the first occurrence of every value, and filter out following duplicates.
 * The method signature is exactly what is required for the callback in Array.filter.
 * Usage: `myArray.filter(isFirstOccurrenceOfValueInArray)` to create an array with unique values.
 *
 * @see https://stackoverflow.com/questions/1960473/get-all-unique-values-in-a-javascript-array-remove-duplicates
 *
 * @param value an entry of the array (for which we want to check if it's the first element with this value in the array)
 * @param index the index of the value in the array
 * @param array the array
 * @returns whether this value is the first one in this array
 */
export const isFirstOccurrenceOfValueInArray = <T>(value: T, index: number, array: T[]): boolean =>
  array.indexOf(value) === index

/**
 * Converts a single element or an array of elements to an array.
 * If the elementOrArray is undefined/null, an empty array is returned.
 *
 * @param elementOrArray array of elements (of the same type) or a single element to be encapsulated into an array
 * @returns an array of elements
 */
export const convertToArray = <Type = any>(elementOrArray: Type | Type[]): Type[] =>
  Array.isArray(elementOrArray) ? elementOrArray : elementOrArray ? [elementOrArray] : []


/**
 * Removes empty entries from a string array
 * and trims all other entries.
 * Useful to prepare data before sending to the API.
 *
 * @param stringArray an array of strings to be cleaned and trimmed
 * @returns a cleaned array with trimmed entries
 */
export const filterAndTrimStringArray = (stringArray: string[]): string[] => {
  return (stringArray ?? []).filter(element => element && element !== '').map(element => element.trim())
}

/**
 * @returns all elements that exists in both arrays
 */
export const arrayIntersect = (a: any[], b: any[]): any[] => a.filter(ele => b.includes(ele))

/**
 * @returns true, if b is part of a, or if b is an array and has intersections with a
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const hasMatches = (a: any[], b: any): boolean =>
  Array.isArray(b) ? arrayIntersect(a, b).length > 0 : a.includes(b)

/**
 * Reduces an array of any objects to an array just of ids of those objects
 *
 * Type of any for objects, b/c it is used for IModel types as well as workpackages/tasks etc.
 * Resulting type of number | string for ids, b/c IModel id uses number, workpackage/tasks uses string
 *
 * @todo typisation https://futureprojects.atlassian.net/browse/FCP-1382
 *
 * @param list An array of objects with an id-attribute.
 * @returns an array of ids only
 */
export const getIDs = (list: any[]): (number | string)[] => list?.map((e: any) => e.id as number | string) || []

// #endregion

// #region uri helper

/**
 * Checks if the URI-Hash of the users window-URL matches the given hashId.
 * This function is used when checking, if the user "jumps" to an element inside a page,
 * e.g. to open it
 *
 * @param hashId the hash-id to be checked
 * @returns true, if the given hashId matches the uriHash of the current URL
 */
export const uriHashMatchesId = (hashId: string): boolean => {
  if (!runsInBrowser()) return false
  if (!window.location.hash) return false
  return window.location.hash === `#${hashId}`
}

/**
 * Removes all characters after # from an URL
 *
 * @param url
 * @returns url without hash
 */
export const urlWithoutHash = (url: string): string => {
  if (url?.indexOf("#") >= 0) {
    return url.substring(0, url.indexOf("#"))
  } else {
    return url
  }
}

/*
 * adds a # to a given string to be used as hash / uri fragment in an URL
 *
 * @param fragment string
 * @returns
 */
export const asUriFragment = (fragment: string): string => {
  return "#" + fragment
}

// #endregion

// #region string functions

/**
 * Removes new line characters in combination with (invisible) whitespace characters in a string
 * so that a maximum of two new line characters are left.
 * Useful to make sure, a string has a maximum of one visible empty line between content to avoid
 * texts with "overlength".
 *
 * @param content the string to be cleared
 * @returns a cleared string
 */
export const removeNewlinesButKeepDoubles = (content: string): string => {
  if (!content) {
    return content
  }

  const filtered = content
    // replace apple or windows newline
    .replace(/\r/g, "\n")
    // remove all white space characters (\s = space, tab, form/line feed and so on) combinations
    // between two outer new line characters (=invisible elements) and keep the two newlines
    .replace(/\n\s*\n/g, "\n\n")

  return filtered
}

/**
 * converts a given string to an integer
 *
 * @param value a string to be converted to an integer
 * @returns converted number, or null, if conversion was not possible
 */
export const stringToInt = (value: string): number =>
  /^\d+$/.exec(value) ? parseInt(value, 10) : null

// #endregion

// #region date comparison
/**
 * Compares two dates.
 * Ensures, that, if the parameter "actual" is a string, it will parsed to a date, because
 * a string can not compared to date, it will also return false.
 *
 * @param actual
 * @param dateToCompare
 * @returns true, if paramater "actual" is bigger than parameter "dateToCompare"
 */
export const dateIsBiggerThan = (actual: string | Date, dateToCompare: Date): boolean => {
  if (actual === null || actual === undefined) {
    return false
  }
  return typeof actual === "string" ? new Date(actual) > dateToCompare : actual > dateToCompare
}
// #endregion

// #region random generator

/**
 * @returns a random integer between min and max
 */
export const randomIntFromInterval = (min: number, max: number): number =>
  Math.floor(Math.random() * (max - min + 1) + min)

// #endregion

// #region time format

/**
 * Converts the timespan given in (milli)seconds to human readable time format: [hh:]mm:ss
 */
export const humanTime = (duration: number, isMilliseconds = true): string => {
  const totalSeconds = isMilliseconds ? Math.floor(duration / 1000) : duration
  const hours = Math.floor(totalSeconds / 60 / 60)
  const minutes = Math.floor((totalSeconds - (hours * 60 * 60)) / 60)
  const seconds = totalSeconds % 60

  const time = minutes.toString().padStart(2, "0") + ":" + seconds.toString().padStart(2, "0")

  return hours > 0
    ? hours.toString() + ":" + time
    : time
}

// #endregion

// #region human readable file size

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 * binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export const humanFileSize = (bytes: number, si = false, dp = 1): string => {
  const thresh = si ? 1000 : 1024

  if (Math.abs(bytes) < thresh) {
    return bytes.toString() + ' B'
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  let u = -1
  const r = 10 ** dp

  do {
    bytes /= thresh
    ++u
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)


  return bytes.toFixed(dp) + ' ' + units[u]
}

// #endregion

// #region window functions

/**
 * checks, if the current session runs in the browser
 *
 * @returns true, if session runs in browser, false if it runs on the server
 */
export const runsInBrowser = (): boolean =>
  typeof window !== "undefined"

// #endregion

// #region download helper

/**
 * The FCP backend allows to download private files (project PDFs, team uploads etc.) via
 * special endpoints which check the authorization and then stream the file.
 * We cannot use GET for those endpoints as we don't want to transport the JWT in the query
 * as it may show up in log files.
 * We also don't want to use XHR to trigger the download as this would load the response into
 * the browser memory and we would need to then provide it as BLOB download.
 * So we use a hidden/temporary form that we submit automatically, this way the browser requests
 * the given file URL with a POST request and the JWT in the body.
 */
export const triggerDownload = (url: string, token: string, newPage = true): void => {
  // check, if the application element is running on the server or in the browser currently => window is just in the browser defined
  if (runsInBrowser()) {
    const form = document.createElement('form')
    form.method = "POST"
    form.action = url
    if (newPage) {
      form.target = "_blank"
    }

    const input = document.createElement('input')
    input.name = "bearer"
    input.type = "hidden"
    input.value = token
    form.appendChild(input)

    document.body.appendChild(form)
    form.submit()
    form.parentNode.removeChild(form)
  }
}

// #endregion

// #region iri and id functions

/**
 * returns the id of an entity, given as IRI-string (["@id"], e.g. /discussions/117) or an IModel
 *
 * In following situation the function will return undefined:
 * -> input has no number
 * -> input is not identified as an "@id" (e.g. /users/7)
 * -> input is neither an "@id" nor an object with an id
 */
export const idFromIModelOrIRI = (entity: IRI | INumericIdentifierModel): number => {
  if (!entity) {
    return undefined
  }

  if (typeof entity === "string") {
    // extract id from iri string
    const lastIndexOfSlash = entity.lastIndexOf("/")
    if (lastIndexOfSlash !== -1) {
      return Number(entity.substring(entity.lastIndexOf("/") + 1))
    }

    return undefined
  }

  if (typeof entity === "object") {
    // use Object.hasOwn instead of entity.hasOwnProperty, because it is more robust,
    // means: if the entity prototype is not Object.prototype, it does not have the hasOwnProperty method

    // even since INumericIdentifierModel has an id prop by definition, it is optional
    if (Object.hasOwn(entity, 'id')) {
      // return the id of an INumericIdentifierModel
      return entity.id
    }

    // if it does not have an id property it may have an IRI
    if (Object.hasOwn(entity, '@id')) {
      // return the number of an '@id' if the entity object has one
      return idFromIModelOrIRI(entity['@id'])
    }
  }

  return undefined
}

/**
 * Returns the slug attribute if existing, otherwise the id
 *
 * @param slugAndNumericIdentifiable The slugAndNumericIdentifiable with slug or ID, or an IRI
 * @returns slug or id
 */
export const slugOrIdFromISlugAndNumericIdentifierModelOrIRI = (slugAndNumericIdentifiable: IRI | ISlugAndNumericIdentifierModel): string => {
  if (slugAndNumericIdentifiable
    && typeof slugAndNumericIdentifiable === "object"
    && Object.hasOwn(slugAndNumericIdentifiable, 'slug') // NOTE this may nor be necessary, since ISlugAndNumericIdentifierModel has a slug prop by definition
    && !!slugAndNumericIdentifiable.slug // NOTE we must test for non-emptyness, since we cannot return undefined before the alternative path (idFromIModelOrIRI) has been went
  ) {
    return slugAndNumericIdentifiable.slug
  }

  return idFromIModelOrIRI(slugAndNumericIdentifiable)?.toString()
}
// #endregion