
/*
* this file collects all blockers and helper functions for common blocker handling
*
* for example @see blockerToSubmitProposal()
*
* @todo muss kompatibel gemacht werden zu blockersForTransition(), bzw. blockersForTransition() muss einbezogen werden in dieses Blocker-Konzept
*/

import { ITransitionList } from "@api/schema"

/**
 * list of common blockers for all usecases
 */
export enum CommonBlockers {
  ObjectNotFound = "error:failure.notFound",
  ModuleNotAvailable = "error:failure.moduleNotAvailable",
  ObjectsDoNotMatch = "error:blocker.objectsDoNotMatch"
}

// those blockers are not used yet (NR: 08.08.2023)
export enum PermissionBlockers {
  CoordinatorRequired = "proposal.submit.allowedOnlyForProjectCoordinator"
}

/**
 * list of Blockers for a challenge to accept submissions
 */
export enum ChallengeSubmissionBlocker {
  SubmissionPeriodNotReached = "common:challenge.blocker.submissionPeriodNotReached",
  SubmissionPeriodPassed = "common:challenge.blocker.submissionPeriodPassed",
  ChallengePaused = "common:challenge.blocker.noSubmissionBecausePaused",
  ChallengeNotOpen = "common:challenge.blocker.noSubmissionBecauseNotOpen",
}

/**
 * list of blockers for submitting a proposal
 */
export enum ProposalSubmissionBlocker {
  ProposalDoesNotMatchProject = "common:proposal.blocker.notMatchingProject",
  ProposalDoesNotMatchChallenge = "common:proposal.blocker.notMatchingChallenge",
  ProposalAlreadySubmitted = "common:proposal.blocker.alreadySubmitted",
  ProjectDeactivated = "common:project.blocker.deactivated",
  ProjectLocked = "common:project.blocker.locked",
  ProposalNotActive = "common:proposal.blocker.notActive",
  ProposalSelfAssessmentNotFulfilled = "common:proposal.blocker.selfAssessmentNotFulfilled",
  ProjectProfileSelfAssessmentNotFulfilled = "common:project.blocker.profileSelfAssessmentNotFulfilled",
  ProjectPlanSelfAssessmentNotFulfilled = "common:project.blocker.planSelfAssessmentNotFulfilled",
  ConcretizationSelfAssessmentNotFulfilled = "common:proposal.blocker.concretizationSelfAssessmentNotFulfilled",
  AttachmentsMissing = "common:proposal.blocker.attachmentsMissing",
  RequestedFundingTooLow = "common:proposal.blocker.requestedFundingTooLow",
  RequestedFundingTooHigh = "common:proposal.blocker.requestedFundingTooHigh",
}

/**
 * type collection of all available blockers
 */
export type AllBlockers =
  CommonBlockers
  | ProposalSubmissionBlocker
  | ChallengeSubmissionBlocker
  | PermissionBlockers


/**
 * blockers and its (future) context
 */
export interface BlockerContext {
  /**
   * list of found blockers within the given context
   */
  blocker: AllBlockers[]
  // there will by more context in future

  // @todo: add functions:
  // getMostImportantBlocker = () => this.blocker[0]
  // addBlocker (newBlockers: BlockerContext) = () => this.blocker = [].concat(this.blocker, newBlockers.blocker)
}

/**
 * @returns true, if a given BlockerContext has at least one blocker
 */
export const hasBlockers = (context: BlockerContext): boolean => context.blocker.length > 0

/**
 * Merges a list of blocker-lists
 * usage: const allBlockers = mergeBlockers(blockersA, blockersB, blockersC)
 *
 * @param blockers a list of blockers
 * @returns the merged blockers
 */
export const mergeBlockers = (...blockers: BlockerContext[]): BlockerContext => {
  const mergedBlockers: BlockerContext = { blocker: [] }
  blockers.forEach(element => {
    mergedBlockers.blocker = [].concat(mergedBlockers.blocker, element.blocker)
  })
  return mergedBlockers
}

// mapped errors to Codes
// export type BlockerToErrorCodeMap = {
//   [code in AllBlockers]: string
// }

/**
 * Preparing a list of blockers for a given transition. If the transition is not allowed, the returned object
 * contains the reasons why it is forbidden (TransitionBlockers).
 *
 * @todo make compatible to system wide blocker system
 *
 * @param transitionList
 * @param transition
 * @returns an object, which contains one more codes (identifier of a TransitionBlocker) and the message itself
 */
export const blockersForTransition = <TransitionState>(transitionList: ITransitionList<TransitionState>, transition: TransitionState): { code: string; msg: string }[] => {
  if (!transitionList) {
    return []
  }

  return Object.keys(transitionList[transition as string]?.blockers || {}).map(code => {
    return { code, msg: transitionList[transition as string].blockers[code] }
  })
}
