import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { IRequestState } from "@modules/frontend-definitions/src"
import { ICallbackHandler } from "@modules/frontend-definitions/src/models/forms"

import { useOIDCToken } from "./useOIDCToken"
import { getOIDCConfig } from "../config"
import { IOIDCProvider } from "../models/IOIDCProvider"
import { OIDCRequestScopes } from "../models/request-states"
import { oidcLoginWithoutTermsAcceptanceAction, oidcLoginWithTermsAcceptanceAction } from "../redux/saga"


/**
 * Return type of the `useLoginWithOIDCToken` hook.
 */
export type OIDCLoginResult<AuthType = any> = {
  /** OIDC provider from which the OIDC token has been requested. */
  provider: IOIDCProvider
  /** Reply from the host application backend, contains the host application auth token. */
  platformAuthReply: AuthType
  /** Request state of the login request. */
  request: IRequestState
  /** A function that dispatches a `oidcLoginWithTermsAcceptanceAction` */
  dispatchRegisterWithAcceptedTermsAction: (handler: ICallbackHandler) => void
}

/**
 * Callback function type to signalize: "login process is running".
 */
export type SetLoggingIn = (loggingIn: boolean) => void

/**
 * Hook to login at the host application backend by using a previously fetched OICD token.
 *
 * @param setLoggingIn Callback to signalize the "login in process" state to the caller.
 * @param reactOnTermsAcceptanceRequired Function that is called when the backend returns the failure "terms acceptance required"
 */
export const useLoginWithOIDCToken = <AuthType = any>(setLoggingIn: SetLoggingIn, reactOnTermsAcceptanceRequired: () => void): OIDCLoginResult<AuthType> => {
  const dispatch = useDispatch()

  const { provider, idToken, accessToken, request: oidcTokenRequest } = useOIDCToken()
  const platformAuthReply = useSelector(getOIDCConfig().selectOIDCState).platformAuthReply as AuthType
  const request = useSelector(getOIDCConfig().requestStateAPI.selectRequestState(OIDCRequestScopes.LoginWithOIDCToken))

  const mergedRequest = getOIDCConfig().requestStateAPI.mergeRequestStates(oidcTokenRequest, request)

  useEffect(() => {
    getOIDCConfig().loggerAPI.debug("useLoginWithOIDCToken: useEffect", platformAuthReply, idToken)
    if (
      // block 1: output data not yet fetched
      !platformAuthReply
      // block 2: input data is available
      && idToken
      && provider
      // block 3: request state is ok
      && (!request || !request.loaded)
      && !request.isLoading
      && !request.loadingError
    ) {
      setLoggingIn(true) // @todo oauth: replace by request.running state or alike
      getOIDCConfig().loggerAPI.debug("useLoginWithOIDCToken: dispatch loginOrRegisterWithOIDCTokenAction")

      dispatch(oidcLoginWithoutTermsAcceptanceAction(provider, idToken, accessToken, null /* = handler */, reactOnTermsAcceptanceRequired))
    }
  }, [
    platformAuthReply,
    idToken,
    provider,
    JSON.stringify(request)
  ])


  const dispatchRegisterWithAcceptedTermsAction = (handler: ICallbackHandler) => {
    getOIDCConfig().loggerAPI.debug("useLoginWithOIDCToken: dispatch loginOrRegisterWithOIDCTokenAction with additional 'acceptedTerms' params")
    dispatch(oidcLoginWithTermsAcceptanceAction(provider, idToken, accessToken, handler, true /* = hasAccepted */))
  }

  return { provider, platformAuthReply, request: mergedRequest, dispatchRegisterWithAcceptedTermsAction }
}