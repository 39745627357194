import { has } from "lodash"
import { Action } from "redux"

import { hasIdPlaceholder, replaceIdPlaceholder } from "@api/client"
import { entityEndpointList } from "@api/entityTypeEndpointDefinitions"
import { OtherRequestErrors } from "@api/requestError"
import { IRIstub } from "@api/schema"
import { platformIsInTestEnvironment } from "@basics/platform"
import { stringToInt } from "@basics/util-importless"
import { CurrentUserActionTypes } from "@redux/actions/currentUser"
import { FeedbackActionTypes } from "@redux/actions/feedback"
import { MyProjectsActionTypes } from "@redux/actions/myProjects"
import { ProcessActionTypes } from "@redux/actions/processes"
import { RegistrationUsecases } from "@redux/actions/registration"
import { UserManagementActionTypes } from "@redux/actions/userManagement"
import { VerificationActionTypes } from "@redux/actions/verification"
import { ActionTypes, EntityType, GeneralApiActionTypes } from "@redux/reduxTypes"
import { TransitionUsecases } from "@redux/usecases/transitions/definitions"
import { DEBUG_LOG_MISSING_API_MOCKS } from "config"

import { IEntityApiRequestInvokingAction } from "./actions"

/**
 * bundled type of all actions processed by the showErrorInTestEnvironment and sub functions
 */
type ActionTypes = GeneralApiActionTypes |
  ProcessActionTypes |
  CurrentUserActionTypes |
  VerificationActionTypes |
  FeedbackActionTypes |
  MyProjectsActionTypes |
  RegistrationUsecases |
  UserManagementActionTypes |
  TransitionUsecases

/**
 * logs additional information to the console in test environment
 * to make it more easy for test developers to develop the matching
 * test situation
 *
 * @param sagaName name of the saga, where the error occurred
 * @param errorMessage the error that occurred
 * @param action the action that triggered the error
 */
export const showErrorsInTestEnvironment = (
  sagaName: string,
  errorMessage: string,
  // @todo: why is this not typed as <any>, b/c the type of the action does
  // not matter in this function but must be extended when a new saga is added
  action: Action<ActionTypes>,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  error: any
): void => {
  // show error data of unexpected errors if in test environment
  // to make it easier to find the source of the problem
  // especially on (un)mocked API calls
  if (platformIsInTestEnvironment()) {
    let unexpectedTestError: string = null
    const mockSuggestion = action ? "Useful mock code may be:\n" + calculateMockSuggestion(action) + "\n" : ""

    switch (errorMessage as OtherRequestErrors) {
      case OtherRequestErrors.NoNetworkResponse:
        unexpectedTestError = "This usually means: the local axios API is not mocked.\n" +
          "Mock it by using AxiosMockHelper/AxiosMockAdapter!\n" +
          mockSuggestion
        break
      case OtherRequestErrors.MissingDataFromApi:
        unexpectedTestError = "This usually means: the mocked axios API does not return needed data.\n" +
          "Check the .onGet(), .onPost() etc. calls and the data they return!\n" +
          mockSuggestion
        break
      case OtherRequestErrors.FailureSendingRequest:
        // example for FailureSendingRequest:
        // "TypeError: Converting circular structure to JSON"
        // when elements within a nested object points to themself and therefor cannot be given to the API
        if (error instanceof Error) {
          unexpectedTestError = "Reason for " + OtherRequestErrors.FailureSendingRequest + ": \n" + JSON.stringify(error.cause)
        } else {
          unexpectedTestError = JSON.stringify(error)
        }
        break
    }

    if (unexpectedTestError) {
      // @see https://stackoverflow.com/questions/9781218/how-to-change-node-jss-console-font-color
      const RESET_COLOR = "\x1b[0m"
      const RED_COLOR_START = "\x1b[31m"
      const YELLOW_COLOR_START = "\x1b[33m"

      if (DEBUG_LOG_MISSING_API_MOCKS) {
        let causeMessage = ""

        // show the "causing error" which should be temporarily stored in the hydraClient before
        // performing the API request
        // but filter the real long stacktrace for files in folders of the projektfabrik project: pages/src/tests
        if (error?.cause) {
          const causeError: Error = (error as Error).cause as Error
          const stackTrace = causeError.stack
          const lines = causeError.stack.split("\n")
          const filteredLines = lines.filter(line =>
            line.indexOf("/pages/") >= 0
            || (line.indexOf("/src/") >= 0 && line.indexOf("/src/api") === -1) // except api/client.ts, b/c it is not helpful
            || line.indexOf("/tests/") >= 0
          )
          const probablyTriggeringTest: string = filteredLines.pop()
          causeMessage = causeError.message + "\nProbably triggering test:\n" + (probablyTriggeringTest ?? stackTrace)
        }

        // eslint-disable-next-line no-console
        console.log(
          RED_COLOR_START, `error in ${sagaName}: ${errorMessage}\non action: `, RESET_COLOR,
          action, "\n\n",
          YELLOW_COLOR_START, unexpectedTestError, RESET_COLOR,
          "\n", causeMessage
        )

      }

      // @todo: this error is not thrown: within the SAGA stack
      // https://futureprojects.atlassian.net/browse/FCP-1339
      // https://github.com/axios/axios/issues/2387
      throw new Error("Error when testing an axios call situation.")
    }
  }
}

/**
 * Calculates a suggestion for code to mock a API call based on the given action.
 *
 * NOTE: coding errors in that function are not reported to the console
 * probably b/c of "losing stacktrace in axios/node" (@see showErrorsInTestEnvironment())
 *
 * @param action an action that lead to an error in test environment; Can have value "unknown", used in tests
 * @returns a string that contains a suggestions for code to mock the API
 */
const calculateMockSuggestion = (
  action: Action<ActionTypes
    | "unknown"
  >
): string => {
  if (!action) {
    return ""
  }

  let actionType = action.type
  // e.g. statistics calls do not have an entityType
  let entityType = has(action, "entityType") && has(action, "usecaseKey") && (action as IEntityApiRequestInvokingAction<EntityType, string>).entityType

  // handling special actions that do not carry an entityType
  if (!entityType) {
    switch (action.type) {
      case ProcessActionTypes.LoadCurrentProcess:
        actionType = ActionTypes.Load
        entityType = EntityType.Program
        break
      case GeneralApiActionTypes.LoadStatistics:
        actionType = ActionTypes.Load
        break
      default:
        actionType = "unknown"
    }
  }

  let method = "onAny"
  switch (actionType) {
    case ActionTypes.Create:
      method = "onPost"
      break
    case ActionTypes.Delete:
      method = "onDelete"
      break
    case ActionTypes.Load:
    case ActionTypes.LoadCollection:
    case ActionTypes.LoadCollectionPage:
      method = "onGet"
      break
    case ActionTypes.Update:
      method = "onPut"
      break
  }

  const entityTypeElementKey = Object.entries(EntityType).find(([/* key */, element]) => element === entityType)?.[0]

  // should the replied mock value be a single entity or a collection?
  const replyValue = [ActionTypes.LoadCollection, ActionTypes.LoadCollectionPage].includes(actionType as GeneralApiActionTypes)
    ? `AxiosMockHelper.mockCollection(EntityType.${entityTypeElementKey}, [entity1, entity2])`
    : entityType ? "entity" : "object"

  // what endpoint is (most probably) tried to be called
  let endpointUrl: IRIstub = entityType
    ? entityEndpointList[entityType].url
    : "/..."

  // inject the parentIri if it is given
  const parentIri = (action as IEntityApiRequestInvokingAction<EntityType, string>).parentIri
  if (parentIri) {
    endpointUrl = parentIri + endpointUrl
  }

  const usecaseKey = (action as IEntityApiRequestInvokingAction<EntityType, string>).usecaseKey
  const calledEndpoint = replaceIdPlaceholder(endpointUrl, usecaseKey)

  // if this is an action to get, change or create a single entity
  // the id of the entity should be used as usecaseKey
  // so it may complete the endpoint url
  const endpointExtension =
    usecaseKey && stringToInt(usecaseKey) && !hasIdPlaceholder(endpointUrl)
      ? "/" + (action as IEntityApiRequestInvokingAction<EntityType, string>).usecaseKey
      : ""

  return `.${method}("${calledEndpoint}${endpointExtension}").reply(200, ${replyValue})`
}