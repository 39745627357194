import { ApplicationActions, FlyoutActionTypes, ISetFlyoutContentURIAction, ISetSettingsCustomerPreviewAction, ISetSettingsDetailedCommentsAction, MapActionTypes, SettingsActionTypes } from "@redux/actions/application"

/**
 * the IApplicationState handles the state of single functionalities of the Digitale Projektfabrik,
 * e.g. the state of the Flyout for the FAQ, or the users editing state on the map
 *
 * it may be extended for other simple states, regarding application functionality
 */
export interface IApplicationState {
  flyout: {
    isVisible: boolean
    /** @todo if we want to display different contents in the flyout, we should use the contentURI  as following => domäne://content; example: faq://introduction.roles.what
     * Currently the contentURI simply contains the faqKey.
     */
    contentURI: string
  }
  /** true, if the user is editing the map to (geo)locate its project */
  mapUserEditing: boolean
  /** settings of the current user in his current session, e.g. for toggling visibility of elements */
  settings: {
    /** show icons for commenting on single contentIds and not only for the whole project */
    detailedComments: boolean
    /** shows preview of a customers platform, e.g. before a function goes live */
    customerPreview: boolean
  }
}

const defaultApplicationState: IApplicationState = {
  flyout: {
    isVisible: false,
    contentURI: ""
  },
  mapUserEditing: false,
  settings: {
    detailedComments: false,
    customerPreview: false
  }
}

/**
 * The applicationStateReducer reduces the state for application functionality states:
 *
 * Flyout: toggling the visibility-state of the Flyout and (re)setting the contentURI
 * Settings: user and session specific settings, e.g. to set elements visible/invisible
 *
 * @param state the current IApplicationState
 * @param action an Action of type ApplicationActions
 * @returns an updated IApplicationState
 */
export const applicationStateReducer = (state: IApplicationState = defaultApplicationState, action: ApplicationActions): IApplicationState => {
  switch (action.type) {
    case FlyoutActionTypes.ToggleFlyoutVisibility:
      return {
        ...state,
        flyout: {
          isVisible: !state.flyout.isVisible,
          contentURI: state.flyout.contentURI
        }
      }
    case FlyoutActionTypes.SetFlyoutContentURI:
      return {
        ...state,
        flyout: {
          isVisible: state.flyout.isVisible,
          contentURI: (action as ISetFlyoutContentURIAction).contentURI
        }
      }
    case MapActionTypes.SetMapEditingState:
      return {
        ...state,
        mapUserEditing: action.editing
      }
    case SettingsActionTypes.SetDetailedComments:
      return {
        ...state,
        settings: {
          ...state.settings,
          detailedComments: (action as ISetSettingsDetailedCommentsAction).detailedComments
        }
      }
    case SettingsActionTypes.SetCustomerPreview:
      return {
        ...state,
        settings: {
          ...state.settings,
          customerPreview: (action as ISetSettingsCustomerPreviewAction).customerPreview
        }
      }
    default:
      return state
  }
}