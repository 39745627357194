import { cloneDeep } from "lodash"
import { useRouter } from "next/router"
import useTranslation from "next-translate/useTranslation"
import { useSelector } from "react-redux"

import { entityTypeFromIModelOrIRI } from "@api/entityTypeEndpointDefinitions"
import { ISlugAndNumericIdentifierModel, IUserObjectRole, UserObjectRoleType, UserRole } from "@api/schema"
import { CHALLENGE_MANAGER_ACCESS, COMMUNITY_MANAGER_ACCESS, LOGGED_IN_USERS_ACCESS, PLATFORM_MANAGER_ACCESS, PROGRAMM_AND_PLATFORM_ACCESS as PROGRAM_AND_PLATFORM_ACCESS, PROJECT_INNER_TEAM_ACCESS, PROJECT_TEAM_ACCESS, RequiredObjectRole, RequiredObjectRoleOnEntity, RouterPageAccessSpecification, TENANT_ACCESS } from "@basics/pageAccess"
import { Routes } from "@basics/routes"
import { convertToArray, hasMatches } from "@basics/util-importless"
import ErrorPage from "@components/ErrorPage"
import SpinnerPage from "@components/SpinnerPage"
import { AppState } from "@redux/reducer"
import { getIntFromQuery, getStringFromQuery } from "@services/routesHelper"
import { getAllUORObjects, hasUOROnAnyObjectRole, matchesRequiredObjectRoles, createRequiredObjectRole } from "@services/userObjectRolesHelper"
import { SINN_PROTOTYPE_CLIENT_CONFIG_USED } from "config"

import { useCurrentUser } from "./useCurrentUser"


/** type of the PageAccessTable */
type PageAccessTableType = {
  [key in Routes]: RouterPageAccessSpecification
}

/**
 * table of all access conditions of the pages, identified by its Route
 *
 * null means: no restrictions
 */
export const PageAccessTable: PageAccessTableType = {
  // #region open for everyone
  [Routes.Home]: null,
  [Routes.About]: null,
  [Routes.Contact]: null,
  [Routes.DataProtection]: null,
  [Routes.Netiquette]: null,
  [Routes.TermsOfUse]: null,
  [Routes.Imprint]: null,
  [Routes.Pubtools]: null,
  [Routes.ChallengePage]: null,
  [Routes.FAQ]: null,

  [Routes.Marketplace]: null,
  [Routes.MarketOfCategories]: null,
  [Routes.PartnerMarket]: null,
  [Routes.ProjectPage]: null,
  [Routes.CreateIdea]: null,
  [Routes.CreateProject]: null,
  [Routes.ProjectApplication]: null,

  [Routes.Login]: null,
  [Routes.Registration]: null,
  [Routes.OAuthVerification]: null,
  [Routes.ConfirmAccount]: null,
  [Routes.ConfirmEmailChange]: null,
  [Routes.ConfirmPasswordReset]: null,
  [Routes.ForgotPassword]: null,

  // this page is not available at all (only used in HeaderIconNavigation), if SINN_PROTOTYPE_CLIENT_CONFIG_USED is set true,
  // b/c with Sinn, it is not wanted to make the program data public
  [Routes.ProgramView]: SINN_PROTOTYPE_CLIENT_CONFIG_USED ? PROGRAM_AND_PLATFORM_ACCESS : null,
  [Routes.SingleMultiCurrentProgram]: null, // @todo für SINN ausschalten?
  // #endregion

  // #region user area, restricted for logged in users
  [Routes.Feedback]: LOGGED_IN_USERS_ACCESS,
  [Routes.ImportProject]: LOGGED_IN_USERS_ACCESS,
  [Routes.UserDashboard]: LOGGED_IN_USERS_ACCESS,
  [Routes.MySupportOffers]: LOGGED_IN_USERS_ACCESS,
  [Routes.MyProjects]: LOGGED_IN_USERS_ACCESS,
  [Routes.SingleDiscussionPage]: LOGGED_IN_USERS_ACCESS, // @todo must this be restricted to owners of the discussion? or will this be checked by the backend?
  // #endregion

  // #region project pages for project team members
  [Routes.ProjectProposalAttachments]: PROJECT_TEAM_ACCESS,
  [Routes.ProjectProfile]: PROJECT_TEAM_ACCESS,
  [Routes.ProjectProfileEdit]: PROJECT_INNER_TEAM_ACCESS,
  [Routes.ProjectSelectChallenge]: PROJECT_INNER_TEAM_ACCESS,
  [Routes.ProjectConcretization]: PROJECT_TEAM_ACCESS,
  [Routes.ProjectConcretizationEdit]: PROJECT_INNER_TEAM_ACCESS,
  [Routes.ProjectFeedbackDashboard]: PROJECT_TEAM_ACCESS,
  [Routes.ProjectFeedbackPage]: PROJECT_TEAM_ACCESS,
  [Routes.ProjectMembers]: PROJECT_TEAM_ACCESS,
  [Routes.ProjectNetworkDashboard]: PROJECT_TEAM_ACCESS,
  [Routes.ProjectNetworkCreateSupportRequest]: PROJECT_INNER_TEAM_ACCESS,
  [Routes.ProjectNetworkSupportRequests]: PROJECT_TEAM_ACCESS,
  [Routes.ProjectNetworkSupportOffersOfOneSupportRequest]: PROJECT_TEAM_ACCESS,
  [Routes.ProjectPartners]: PROJECT_TEAM_ACCESS,
  [Routes.ProjectOwnContributions]: PROJECT_TEAM_ACCESS,
  [Routes.ProjectPlan]: PROJECT_TEAM_ACCESS,
  [Routes.ProjectPlanDescription]: PROJECT_TEAM_ACCESS,
  [Routes.ProjectMap]: PROJECT_TEAM_ACCESS,
  [Routes.ProjectPlanTargetgroupsImpact]: PROJECT_INNER_TEAM_ACCESS,
  [Routes.ProjectPlanTasks]: PROJECT_TEAM_ACCESS,
  [Routes.ProjectPlanWorkPackages]: PROJECT_TEAM_ACCESS,
  [Routes.ProjectPlanTimetable]: PROJECT_TEAM_ACCESS,
  [Routes.ProjectPlanResourceRequirements]: PROJECT_TEAM_ACCESS,
  [Routes.ProjectPlanResourceCostCategories]: PROJECT_TEAM_ACCESS,
  [Routes.ProjectPlanFinances]: PROJECT_TEAM_ACCESS,
  [Routes.ProjectProposals]: PROJECT_TEAM_ACCESS,
  [Routes.ProjectStandingData]: PROJECT_TEAM_ACCESS,
  [Routes.ProjectStandingDataEdit]: PROJECT_INNER_TEAM_ACCESS,
  [Routes.ProjectProposalChallengeModuleInactive]: PROJECT_TEAM_ACCESS,
  [Routes.ProjectProposalInactive]: PROJECT_TEAM_ACCESS,
  [Routes.ProjectProposalAfterSubmission]: PROJECT_TEAM_ACCESS,
  [Routes.ProjectProposalSubmission]: PROJECT_TEAM_ACCESS,
  [Routes.ProjectTeamMeeting]: PROJECT_TEAM_ACCESS,
  [Routes.ProjectTeamUpload]: PROJECT_TEAM_ACCESS,
  [Routes.ProjectSummary]: PROJECT_TEAM_ACCESS,
  // #endregion


  // #region tenant area
  [Routes.TenantCreate]: LOGGED_IN_USERS_ACCESS,
  [Routes.TenantCreateProgram]: TENANT_ACCESS,
  [Routes.TenantDashboard]: TENANT_ACCESS,
  [Routes.TenantEdit]: TENANT_ACCESS,
  [Routes.TenantProgramCategories]: TENANT_ACCESS,
  [Routes.TenantProgramCategoriesAdd]: TENANT_ACCESS,
  [Routes.TenantProgramCategoriesEdit]: TENANT_ACCESS,
  [Routes.TenantProgramEdit]: TENANT_ACCESS,
  [Routes.TenantView]: null,
  // #endregion

  // #region communitymanager
  [Routes.ProgramDashboard]: COMMUNITY_MANAGER_ACCESS,
  [Routes.CommunityManagerProgramCategories]: COMMUNITY_MANAGER_ACCESS,
  [Routes.ProgramProjects]: COMMUNITY_MANAGER_ACCESS,
  [Routes.ProgramIdeas]: COMMUNITY_MANAGER_ACCESS,
  // #endregion communitymanager

  // #region PlatformManager
  [Routes.PlatformManagerDashboard]: PLATFORM_MANAGER_ACCESS,
  [Routes.PlatformPageAccessOverview]: PLATFORM_MANAGER_ACCESS,
  [Routes.PlatformSystemOverview]: PLATFORM_MANAGER_ACCESS,
  [Routes.PlatformSystemIcons]: PLATFORM_MANAGER_ACCESS,
  // #endregion

  // #region old management pages
  // @todo cleanup
  // @todo multi: wer kriegt hier in welcher Rolle wie Zugriff?
  [Routes.AdminDashboard]: PROGRAM_AND_PLATFORM_ACCESS,
  [Routes.AdminProjectSearch]: COMMUNITY_MANAGER_ACCESS,
  [Routes.ManagerProjectDetails]: COMMUNITY_MANAGER_ACCESS,
  [Routes.AdminProjectProfile]: COMMUNITY_MANAGER_ACCESS,

  [Routes.ManagerUserSearch]: PROGRAM_AND_PLATFORM_ACCESS,
  [Routes.ManagerUserDetails]: PROGRAM_AND_PLATFORM_ACCESS,
  // #endregion

  // #region challenge/fund area
  [Routes.AdminChallengeOverview]: CHALLENGE_MANAGER_ACCESS,
  [Routes.AdminChallengeCreate]: CHALLENGE_MANAGER_ACCESS,

  [Routes.AdminChallengeDetails]: CHALLENGE_MANAGER_ACCESS,
  [Routes.AdminChallengeDetailsEdit]: CHALLENGE_MANAGER_ACCESS,
  [Routes.AdminChallengeConcretization]: CHALLENGE_MANAGER_ACCESS,
  [Routes.AdminChallengeConcretizationCreate]: CHALLENGE_MANAGER_ACCESS,
  [Routes.AdminChallengeConcretizationEdit]: CHALLENGE_MANAGER_ACCESS,
  [Routes.AdminChallengeProcedureAndTimeline]: CHALLENGE_MANAGER_ACCESS,
  [Routes.AdminChallengeProcedureAndTimelineEdit]: CHALLENGE_MANAGER_ACCESS,
  [Routes.AdminChallengeViewProposals]: CHALLENGE_MANAGER_ACCESS,
  [Routes.AdminChallengeSelectProposals]: CHALLENGE_MANAGER_ACCESS,
  [Routes.AdminChallengeGrant]: CHALLENGE_MANAGER_ACCESS,
  [Routes.AdminChallengeGrantEdit]: CHALLENGE_MANAGER_ACCESS,

  [Routes.AdminChallengeTransitionPage]: CHALLENGE_MANAGER_ACCESS,
  // #endregion challenges/fund area

  // #region feedback for management
  [Routes.AdminFeedbackInvitationTimeline]: COMMUNITY_MANAGER_ACCESS,
  [Routes.AdminFeedbackInvitationActivate]: COMMUNITY_MANAGER_ACCESS,
  [Routes.AdminFeedbackInvitationCreate]: COMMUNITY_MANAGER_ACCESS,
  [Routes.AdminFeedbackInvitationEdit]: COMMUNITY_MANAGER_ACCESS,
  [Routes.AdminFeedbackInvitationResults]: COMMUNITY_MANAGER_ACCESS,
  [Routes.AdminFeedbackInvitations]: COMMUNITY_MANAGER_ACCESS,
  [Routes.AdminFeedbackInvitationView]: COMMUNITY_MANAGER_ACCESS,
  // #endregion

  // download-page for files/pdf
  // access check is done by the backend
  [Routes.DownloadTriggerPage]: null,
}


/**
 * return values from the usePageAccessCheck hook
 */
export interface IUsePageAccessCheckData {
  /** does the calling user has access to the page? */
  hasAccess: boolean
  /** are loading processes still running and the hasAccess result may be temporary and could change after the loading has finished? */
  isLoading: boolean
  /** page to be shown to the user: spinner if still loading, error if no access is gained or a loading error occurred */
  accessErrorOrSpinnerPage: JSX.Element
}

/**
 * Checks if a user has access permissions to the page that calls this hook.
 * Access permissions are defined in the PageAccessTable:
 * permission/restrictions are evaluated with OR conjunction: at least ONE of the permissions
 * must match and NONE of the restrictions must be fulfilled to gain access.
 *
 * The hook "translates" the abstract permission declarations of the PageAccessTable into a specific entity iri
 * by loading data if needed and into RequiredObjectRoles and then performes the access check.
 *
 * NOTE:
 * For tests: set router.asPath to the real route to make sure the hook is able to make the calculations like in
 * production mode, e.g.
 * asPath: Routes.ProjectMembers.replace("[slug]", project.slug)
 */
export const usePageAccessCheck = (pageRoute: Routes): IUsePageAccessCheckData => {
  const router = useRouter()
  const { t } = useTranslation("common")

  // #region prepare page and access specific data

  // calculate the abstract page route from the router.asPath URL; may be null if route is not to be found
  const currentRoute = pageRoute // Object.entries(Routes).find(([/* key */, route]) => linkMatchesRoute(router.asPath, route))?.[1]
  // get the pageAccessConditions to the currentRoute, may be undefined if there is no definition for this route
  const pageAccessConditions = PageAccessTable[currentRoute]

  // prepare boolean values which permissions are required based on the PageAccessTable entry
  const uorRequired: boolean = !!pageAccessConditions && !!pageAccessConditions.requiredRouteBasedUserObjectRole
  const uorOnAnyObjectRequired: boolean = convertToArray<UserObjectRoleType>(pageAccessConditions?.requiredUOROnAnyObject).length > 0
  // #endregion

  // #region get the user's UserObjectRoles and UserRoles

  const { userObjectRoles, mergedUserRequest } = useCurrentUser(uorRequired || uorOnAnyObjectRequired)

  const roles = useSelector((state: AppState) => state.auth.roles)

  // #endregion

  // #region get the page specific slug or id if there is such a specification

  // get the slug or id from the router depending on the pageAccessConditions
  let slug: string = null
  let entityId: number = null
  switch (pageAccessConditions?.requiredRouteBasedUserObjectRole?.identifierParamName) {
    case "id":
      entityId = getIntFromQuery(router, "id")
      break
    case "slug":
      slug = getStringFromQuery(router, "slug")
      break
  }

  // #endregion

  // #region calculate the entity IRI

  const uorObjects = getAllUORObjects(userObjectRoles, true)
  const slugOrIdObjectFromUOR = uorObjects.find(obj =>
    entityTypeFromIModelOrIRI(obj) === pageAccessConditions?.requiredRouteBasedUserObjectRole?.entityType
    && ((slug && (obj as ISlugAndNumericIdentifierModel).slug === slug)
      || (entityId && obj.id === entityId)
      // fallback: if the entity has just created,
      // it is possible, that it has not yet a slug, but only an id
      || (slug && obj.id.toString() === slug))
  )
  // #endregion

  // #region calculate if the user hasAccess

  // expand the pageAccessConditions by the real requiredUserObjectRole
  // cloning is important, otherwise the requiredObjectRole may be overwritten for further usage
  const accessSpecification: RequiredObjectRoleOnEntity = cloneDeep(pageAccessConditions)
  if (pageAccessConditions?.requiredRouteBasedUserObjectRole) {
    accessSpecification.requiredObjectRole = createRequiredObjectRole(slugOrIdObjectFromUOR, pageAccessConditions.requiredRouteBasedUserObjectRole.userObjectRole)
  }

  const hasAccess = userHasAccess(accessSpecification, userObjectRoles, roles)
  // #endregion

  // #region calculate the errorOrSpinnerPage

  // isLoading is only relevant if any UserObjectRole is needed
  // b/c no entity is needed for the other restriction evaluations
  const isLoading = (uorRequired || uorOnAnyObjectRequired) && mergedUserRequest.isLoading
  let errorOrSpinnerPage: JSX.Element = null
  // if isLoading is relevant deliver the SpinnerPage with priority
  if (isLoading) {
    errorOrSpinnerPage = <SpinnerPage title={t("page.loading.title")} description={t("page.loading.title")} />
  } else {
    // order matters:
    // mayor priority have errors when the slug/id is necessary but not found
    // priority has the information of the user has access or not
    // minor priority has if an error occured on loading
    if (pageAccessConditions?.requiredRouteBasedUserObjectRole?.identifierParamName && !slug && !entityId) {
      // if slug or id is necessary but missing
      errorOrSpinnerPage = <ErrorPage statusCode={404} error={"failure.object.notFound"} />
    } else if (!hasAccess) {
      // if isLoading is not relevant deliver the !hasAccess ErrorPage with priority
      errorOrSpinnerPage = <ErrorPage statusCode={403} error={"failure.notAuthorized"} />
    } else if (mergedUserRequest.loadingError) {
      errorOrSpinnerPage = <ErrorPage statusCode={404} error={mergedUserRequest.loadingError} />
    }
  }
  // #endregion

  return { hasAccess, isLoading, accessErrorOrSpinnerPage: errorOrSpinnerPage }
}

/**
 * function to calculate, if a user with specific userObjectRoles and userRoles has access to when checking
 * requiredConditions
 *
 * @param requiredConditions the conditions a user must met to gain access to an element
 * @param userObjectRoles the user's UserObjectRoles
 * @param userRoles the user's UserRoles
 * @returns true, if the conditions are met, false if not
 */
export const userHasAccess = (
  requiredConditions: RequiredObjectRoleOnEntity,
  userObjectRoles: IUserObjectRole[],
  userRoles: UserRole[]): boolean => {

  // convert all props into arrays even if they come as single elements
  const requiredUserObjectRole = convertToArray<RequiredObjectRole>(requiredConditions?.requiredObjectRole)
  const requiredUserRole = convertToArray<UserRole>(requiredConditions?.requiredUserRole)
  const requiredUOROnAnyObject = convertToArray<UserObjectRoleType>(requiredConditions?.requiredUOROnAnyObject)
  const forbiddenUserRole = convertToArray<UserRole>(requiredConditions?.forbiddenUserRole)
  const forbiddenUOROnAnyObject = convertToArray<UserObjectRoleType>(requiredConditions?.forbiddenUOROnAnyObject)

  // prepare boolean values which permissions are required based on the PageAccessTable entry
  const uorRequired: boolean = requiredUserObjectRole.length > 0
  const userRoleRequired: boolean = requiredUserRole.length > 0
  const uorOnAnyObjectRequired: boolean = requiredUOROnAnyObject.length > 0
  const forbiddenUserRoleRequired: boolean = forbiddenUserRole.length > 0
  const forbiddenUOROnAnyObjectRequired: boolean = forbiddenUOROnAnyObject.length > 0

  // is a role forbidden and does the user has such forbidden role?
  const hasForbiddenRole = (forbiddenUserRoleRequired && hasMatches(userRoles, forbiddenUserRole))
    || (forbiddenUOROnAnyObjectRequired && hasUOROnAnyObjectRole(userObjectRoles, forbiddenUOROnAnyObject))

  // are roles needed?
  const needsRequiredRole = uorRequired || userRoleRequired || uorOnAnyObjectRequired
  // are roles needed and does the user have at least one of those roles?
  const needsAndHasRequiredRole = !needsRequiredRole || (needsRequiredRole &&
    ((
      (uorRequired && matchesRequiredObjectRoles(
        userObjectRoles,
        requiredUserObjectRole
      ))
      || (userRoleRequired && hasMatches(userRoles, requiredUserRole))
      || (uorOnAnyObjectRequired && hasUOROnAnyObjectRole(userObjectRoles, requiredUOROnAnyObject))
    )))

  // does the user have access? no restrictions OR matching needed roles AND not having a forbidden role
  return (!requiredConditions || needsAndHasRequiredRole) && !hasForbiddenRole
}